import Vue from 'vue';
import Vuex from 'vuex';
import base from './modules/base';
import shop from './modules/shop';
import wms from "./modules/wms";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    ...base.state,
    ...shop.state,
    ...wms.state
  },
  mutations: {
    ...base.mutations,
    ...shop.mutations,
    ...wms.mutations
  },
  actions: {
    ...base.actions,
    ...shop.actions,
    ...wms.actions
  },
  modules: {}
});
