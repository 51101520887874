
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 处理对应code码对应的动态错误信息
 * @author jing
 * @date 2023/7/13 15:52
 * @params:
 */
import {isJSON, isValueEmpty, setConfigCharacters} from "@/utils/common";

// 处理数组数据问题
function handleArrayData(data) {
  if (Array.isArray(data)) {
    return data.join('、');
  } else {
    return data;
  }
}

export const dynamicErrorCodeFun = (data, value) => {
  if (data) {
    let codeObj = {};
    let code = data.code;
    let message = data.others ? data.others.errorMessage : '';
    let jsonTalg = isJSON(message);
    let text = '';
    if (jsonTalg) {
      let obj = JSON.parse(message);
      let ymsWarehouseName = '';
      let originalYmsWarehouseData = localStorage.getItem('originalYmsWarehouseData') ? JSON.parse(localStorage.getItem('originalYmsWarehouseData')) : [];
      if (originalYmsWarehouseData.length > 0) {
        if (obj.ymsWarehouseId) {
          ymsWarehouseName = originalYmsWarehouseData.find(item => item.ymsWarehouseId === obj.ymsWarehouseId).name;
        }
      }
      if (obj.ymsSpus) {
        codeObj['112812'] = `${obj.ymsSpus.join('、')}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001542')}${ymsWarehouseName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001543')}`
      }
      if (!isValueEmpty(obj.message)) {
        codeObj['1112810'] = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001544')}${obj.message}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001545')}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    } else {
      codeObj = {
        220029: `SKU: ${message} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001546')}`,
        220031: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001547')}${message} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001548')}`,
        220032: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001549')} ${message} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001550')}`,
        1112765: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001551')} ${message}`,
        1112772: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001552')} ${message}`,
        1112777: `${message}`,
        1112778: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001553')} ${message} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001554')}`,
        111294: `${message} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001555')}`,
        111297: `${message}`,
        1112759: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001556')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001557')} ${message}`,
        1112764: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001558')} ${message}`,
        1112803: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001559')}${message}`,
        111190: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001560')}${handleArrayData(message)}`,
        112834: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001561')}${message} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001562')}`,
        1112739: `${message}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001563')}`,
        122008: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001564')}${message}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001565')}`,
        1112760: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001566')}${message}`
      }
      for (let key in codeObj) {
        if (Number(key) === code) {
          text = codeObj[key]
        }
      }
    }
    return text;
  }
}