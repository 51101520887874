/**
 * @Description: 公共接口api
 * @author jing
 * @date 2021/11/22 14:35
 * @params: connomApi 是云卖系统（运营、供应商、分销商、商城）共同可以通用的接口
 */

const commonApi = {
  // 用户
  get_token: '/auth/', // get token
  get_refresh_token: '/refresh/', // 刷新token
  get_logout: '/logout/', // 登出
  get_erpConfig: '/erpCommon/loadConfig', // 用户信息
  put_updateTimeZone: '/erpCommon/updateTimeZone', // 设置时区

  get_currencyInfo: '/erpCommon/common-service/currencyInfo/query', // 获取币种
  erpCommon_uploadFiles: '/erpCommon/uploadFiles', // 上传图片的接口
  get_loadUrlTxt: '/erpCommon/loadUrl', // 读取url文本
  post_uploadFiles: '/erpCommon/uploadFiles', // 批量上传图片
  post_erpCommon_disableNoticeInfo: '/erpCommon/disableNoticeInfo', // 不再提醒版本迭代通知
  get_erpCommon_queryNoticeInfo: '/erpCommon/queryNoticeInfo', // 查询版本迭代通知
  get_ymsMerchant_queryByIds: '/erpCommon/common-service/ymsMerchant/queryByIds', // 获取商户
  get_enableShippingMethods: '/carrierShippingMethod/queryForEnableShippingMethods', // 物流-根据物流方式查询有效运输方式
  get_enableCarriers: '/carrier/queryForEnableCarriers', // 查询可用物流
  post_carrierShippingMethod_queryCarrierShippingName: '/carrierShippingMethod/queryCarrierShippingName', // 物流-批量查询邮寄方式
  get_carrierShippingMethod_queryByIds: '/carrierShippingMethod/queryByIds', // 物流-批量查询邮寄方式
  get_carrierParam_queryForShippingMethodParams: '/carrierParam/queryForShippingMethodParams', // 物流渠道配置
  get_userInfo_queryMapByUserIds: '/erpCommon/common-service/userInfo/queryMapByUserIds', // 根据用户Id列表查询用户信息
  post_createTicket: '/erpCommon/common-service/userInfo/createTicket', // 创建ticket
  get_restartExport: '/erpCommon/restartExport', // 重启下载任务
  post_erpCommonSettingParam_saveList: '/erpCommonSettingParam/saveList', // 保存设置导出的自定义字段
  post_erpCommonSettingParam_queryByUK: '/erpCommonSettingParam/queryByUK', // 获取自定义导出的字段
  put_erpCommonSettingParam_cutoverLang: '/erpCommonSettingParam/cutoverLang/', // 切换语种

  get_ymsExportTemplate_detail: '/ymsExportTemplate/detail/', // 获取导出模板详情数据
  delete_ymsExportTemplate: '/ymsExportTemplate/', // 删除导出模板
  put_ymsExportTemplate: '/ymsExportTemplate', // 更新导出模板
  post_ymsExportTemplate_queryList: '/ymsExportTemplate/queryList', // 获取当前用户所有导出模板的数据
  post_ymsExportTemplate_insert: '/ymsExportTemplate/insert', // 新增导出模板
};

export default commonApi;


