<template>
  <div>
    <div class="moreBtn">
      <Button
        :class="firBtn"
        ref="mButton"
        :type="fixData.btn.type || 'default'"
        @click="fixData.btn.clickFn()"
        :disabled="fixData.btn.disabled">{{ fixData.btn.text }}</Button>
      <Select v-model="model" filterable :style="style" transfer ref="moreButton" v-if="fixData.list && fixData.list.length > 0 && fixData.list.some(i => !i.hide)">
        <Option
          v-for="(item, index) in fixData.list"
          v-show="!item.hide"
          :value="item.value"
          :disabled="item.disabled ? item.disabled : false"
          @click.native="item.clickFn"
          :key="index">
          <span>{{ item.text }}</span>
        </Option>
      </Select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'moreButton',
  props: {
    data: {
      type: Object
    },
    dropWidth: {
      default: 120
    }
  },
  mounted() {
    this.wid = this.$refs.mButton.$el.offsetWidth;
    // this.$refs.moreButton.$refs.dropdown.$el.target.style.width = '100px'
  },
  data() {
    return {
      wid: '',
      model: ''
    };
  },
  computed: {
    style() {
      return {
        width: this.wid + 16 + 'px'
      };
    },
    firBtn() {
      if (this.fixData.list && this.fixData.list.length > 0 && this.fixData.list.some(i => !i.hide)) {
        return 'firBtn'
      }
    },
    fixData() {
      if (this.data) {
        if (this.data.btn) {
          return this.data
        } else {
          let index = null;
          let datas = JSON.parse(JSON.stringify(this.data));
          datas.list.forEach((i, ind) => {
            if (!i.hide) {
              index = ind;
            }
          })
          if (index !== null) {
            datas.btn = datas.list.splice(index, 1)[0];
          }
          return datas;
        }
      }
    }
  },
  methods: {}
};
</script>

<style>
.moreBtnSelectDorp {
  width: 120px;
}

.moreBtn .ivu-select-arrow {
  right: 0px !important;
}

.moreBtn .ivu-select-selection:hover,
.moreBtn .ivu-select-selection-focused {
  border-color: #dcdee2;
  background-color: #eee;
}

.moreBtn .ivu-select-visible .ivu-select-selection {
  border-color: #dcdee2;
  box-shadow: 0 0 0 0;
}

.ivu-select-item-selected {
  color: #515a6e !important;
}
</style>
<style scoped>
.moreBtn {
  display: inline-block;
  position: relative;
  height: 32px;
}

.moreBtn .firBtn {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  cursor: pointer;
}

</style>
