/**
 * @Description: 支持列表操作栏可以插入自定义的render函数内容
 * @author jing
 * @date 2023/11/15 15:24
 * @params: paramsData：列表行参数， renderCustomContent：对应自定义的render函数
 */

export default {
  functional: true,
  props: {
    paramsData: { // 传入的参数
      type: Object,
      default: () => {
      }
    },
    renderCustomContent: Function // 传入的render函数
  },
  /**
   * render渲染函数
   * @param {Function} h - render函数的回调方法，用于生成dom节点
   * @param {Object} ctx - 指代当前的这个对象
   */
  render: (h, ctx) => {
    return ctx.props.renderCustomContent(h, ctx.props.paramsData)
  }
}