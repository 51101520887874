/**
 * @Description: 全局注册常用的公共组件
 * @author jing
 * @date 2020/12/25 10:44
 * @params:
 */
import Vue from 'vue';
import tabButton from '@/components/localComponents/tabButton/tabButton';
import imgZoom from '@/components/localComponents/imgZoom/imgZoom';
import filterColumns from '@/components/localComponents/filterColumns/filterColumns';
import moreButton from '@/components/localComponents/moreBtn/moreButton';
import treeSelect from "@/components/localComponents/tree-select";
import rollingNumber from '@/components/localComponents/rollingNumber/rollingNumber'
import buttons from '@/components/localComponents/buttons/buttons'
import optsButtonList from '@/components/localComponents/operationButton/optButtonList';
import Alink from '@/components/localComponents/aLink/aLink';
import becomingSupplierDistributorModal from "@/components/common/becomingSupplierDistributorModal";
import uDate from '@/utils/tools/date';

Vue.prototype.$uDate = uDate;
const install = function (Vue) {
  if (install.installed) return;
  Vue.component('local-tabButton', tabButton);
  Vue.component('local-imgZoom', imgZoom);
  Vue.component('local-filterColumns', filterColumns);
  Vue.component('local-down-button', moreButton);
  Vue.component('local-rolling-number', rollingNumber);
  Vue.component('local-buttons', buttons);
  Vue.component('treeSelect', treeSelect);
  Vue.component('optsButtonList', optsButtonList);
  Vue.component('Alink', Alink);
  Vue.component('becomingSupplierDistributorModal', becomingSupplierDistributorModal)
}
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

Vue.use(install);
