import { render, staticRenderFns } from "./termsOfServiceCN.vue?vue&type=template&id=88ef663c&scoped=true&"
import script from "./termsOfServiceCN.vue?vue&type=script&lang=js&"
export * from "./termsOfServiceCN.vue?vue&type=script&lang=js&"
import style0 from "./termsOfServiceCN.vue?vue&type=style&index=0&id=88ef663c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88ef663c",
  null
  
)

export default component.exports