<template>
  <div style="display: flex;overflow: hidden" :style="numberStyle">
    <div v-for="(item,index) in list" :key="item" :class="itemClassName + 'item'+index">
      <div v-if="showNumber">
        <p v-for="number in numberList">{{number}}</p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'rollingNumber',
  props: {
    numberStyle: {
      type: Object,
      default: () => {
        return {
          height: '30px'
        }
      }
    },
    lineHeight: {
      type: Number,
      default: 30
    },
    startNumber: {
      type: Number,
      required: true
    },
    endNumber: {
      type: Number,
      required: true
    },
    transitionTime: {
      type: Number,
      default: 2
    },
    itemClassName: {
      type: String
    }
  },
  data () {
    return {
      list: [],
      numberList: [...Array.from({length:10},(i,j)=> j),'.'],
      showNumber: false,
    };
  },
  created () {
    this.initNumber(this.startNumber,false)
      .then(()=>{
        this.initNumber(this.endNumber,true)
      })
  },
  methods: {
    initNumber (startNumber,isTransition) {
      this.list = [];
      return new Promise(resolve => {
        this.showNumber = false;
        for (let i = 0 ;i<startNumber.toString().length;i++) {
          this.list.push(startNumber[i]);
          let translateIndex = 0
          if (startNumber.toString()[i] === '.') {
            translateIndex = 10
          } else {
            translateIndex = startNumber.toString()[i]
          }

          this.translateHandle(i,translateIndex,isTransition).then((index)=>{
            if (index === startNumber.toString().length - 1) {
              this.showNumber = true;
              resolve();
            }
          });
        }
      })
    },
    translateHandle (index,translateIndex,isTransition) {
      return new Promise(resolve => {
        setTimeout(()=>{
          let dom = document.getElementsByClassName(this.itemClassName + 'item'+index)[0];
          let x = this.lineHeight * translateIndex;
          if (isTransition) {
            dom.style.transition = this.transitionTime + "s";
          }
          dom.style.transform = "translateY(-"+x+"px)";
          resolve(index)
        },20)
      })
    }
  },
  watch: {
    endNumber(n,o) {
      // debugger
      this.initNumber(this.endNumber,true)
    }
  }
};
</script>

<style scoped>

</style>
