<template>
  <Button-group>
    <template v-for="(item, index) in tabButtonList">
      <Button v-if="item.selected" type="primary" :size="size" @click="selectStatus(index, item.value, item)" :key="index">
        {{ item.title }}
        <span v-if="item.number >=0">{{ '(' + item.number + ')' }}</span>
      </Button>
      <Button v-if="!item.selected" @click="selectStatus(index, item.value, item)" :key="index" :size="size">
        {{ item.title }}
        <span v-if="item.number >=0">{{ '(' + item.number + ')' }}</span>
      </Button>
    </template>
  </Button-group>
</template>

<script>
/**
 * @Description: 公用的tab button筛选按钮组件
 * @author jing
 * @date 2020/12/25 10:22
 * @params: 接收一个数组
 * 例：[{
 selected: true,  // 是否默认选中
 value: null,    // 默认值
 title: '全部'  // 默认标题
 }
 ]
 */
export default {
  name: 'tabButton',
  props: {
    tabButtonList: {
      type: Array,
      default: []
    },
    size: {
      default: 'default'
    }
  },
  methods: {
    selectStatus(index, value, item) {
      this.$emit('selectStatus', index, value, item);
    }
  }
};
</script>

<style scoped>
.ivu-btn {
  padding: 0 11px !important;
}
</style>
