// @all-no-translate
<template>
  <div class="Section0" style="layout-grid:15.6000pt;">
    <h2 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(29,32,38);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">YMS Platform Privacy Policy</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(29,32,38);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></b></h2>
    <h1 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;"><font face="微软雅黑">Updated on November 3, 2021</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;"></span></h1>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">I</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">ntroduction</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Welcome to use our products and services! We are well aware of the importance of personal information to you, and your trust is very important to us. We will provide sufficient protection for the security of your personal information in accordance with legal and regulatory requirements and in accordance with industry best practices. In view of this, the YMS platform service provider (or referred to as "us") has formulated this Privacy Policy (hereinafter referred to as "this Policy"/"this Privacy Policy") to help you fully understand how we collect, use, share, store, and protect your personal information during your use of our products and services, as well as how you can manage your personal information, so that you can make appropriate choices better.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Before you start using our products and services, please carefully read and understand this policy, especially the terms marked in bold/underlined, to ensure that you fully understand and agree before starting to use. We will try to explain the professional vocabulary involved in this policy to you in concise and easy to understand terms. If you have any questions, opinions or suggestions regarding the content of this policy, you can contact us through the contact information at the end of this policy.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Your agreement to the privacy policy indicates that you have understood the functions provided by the application and the necessary personal information required for its operation, and have granted corresponding collection and use authorization. However, it does not mean that you have separately agreed to enable additional functions, process unnecessary personal information, and process sensitive personal information. We will separately seek your consent based on your actual use.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">This privacy policy will help you understand the following:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、 Applicable scope</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、 Information collection and use</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、 Provide information externally</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、 Your Rights</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">5、 Storage of Information</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">6、 Policy updates</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">7、 Protection of minors</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">8、 Contact Us</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Appendix: Relevant Definitions1、 Applicable scope</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">This policy applies to the various products and services provided to you by YMS platform service providers through websites, clients, mini programs, and new forms that have emerged with technological development. If we have a separate privacy policy for the products or services they provide to you, the corresponding privacy policy applies to the corresponding products or services.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Except for the relevant information collection and use activities described in this policy, this policy does not apply to other services provided to you by YMS platform suppliers and other third parties. Other services are subject to the personal information collection and use rules separately explained to you. For example, when you join a supplier, their use of membership information is governed by the rules they specifically explain to you.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、 Information collection and use</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">When you use our products/services, we need/may need to collect and use your personal information, including the following two types:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. In order to provide you with the basic functions of our products and/or services, you must authorize us to collect and use the necessary information. If you refuse to provide the corresponding information, you will not be able to use our products and/or services normally;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. In order to provide you with additional features of our products and/or services, you may choose to agree or disagree with the information we collect and use separately. If you refuse to provide it, you will not be able to use the relevant additional functions normally or achieve the desired functional effects, which will not affect your use of our basic functions.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We would like to remind you that we are committed to creating a diverse range of products and services to meet your needs. Due to the wide variety of products and services we provide to you, and the specific range of products/services that different users choose to use, there may be differences in the types and scope of basic/additional functions and corresponding personal information collected and used. Please refer to the specific product/service functions.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">The specific functional scenarios we will provide for you include:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">1） Help you become our member and account management</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Basic membership services</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We provide services for you based on our YMS platform account. In order to create a YMS platform account, you need to provide us with at least your phone number, intended member name, and password. If you refuse to provide the above information, you will not be able to register for a YMS platform account and can only use browsing and search services.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We will determine your current level based on the usage of your YMS platform account and the account level calculation rules set by the platform, and provide you with the basic benefits corresponding to the corresponding account level.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Additional membership services</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Special membership benefits: If you provide the following additional information to complete the information, it will help us provide you with more personalized products or services: your real name, fixed phone number, contact information, main industry, nickname and avatar. We can also provide special membership services for you. Among them, the nickname and avatar you set will be publicly displayed.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Real person authentication: You can submit corresponding identity document information (ID card, passport, and other identity document information) and facial information according to the authentication requirements to complete real person authentication.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Authorized login: We may obtain your third-party account information from a third party based on your individual consent and bind it to your YMS platform account, allowing you to directly log in and use our products and/or services from the third party. We will use your relevant information within the authorized scope that you have separately agreed to.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Member Role Services: If you apply to register as a distributor, supplier, or other member role with additional identity authentication requirements, you need to proactively provide us with identity information and/or enterprise related information for the verification, registration, publicity, and other purposes explicitly notified by us to obtain your identity and other membership roles.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">If you already have a YMS platform account, we may display your personal information mentioned above in the YMS platform services, as well as the operations you perform on the YMS platform or in the products and services associated with the YMS platform account (you can use our products or services through the YMS platform account on our website or through the link or other product/service portal provided by the client), including displaying your personal information through the YMS platform account Preferential benefits, transaction orders. We will respect your choices regarding YMS platform services and account settings.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">2） Show you product or service information</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font
      face="微软雅黑">To display product or service information to you, including your visit footprint, historical search, and other information, we will collect and use your browsing and search records when visiting or using the YMS platform website or client. We will combine legally collected device information, service log information, and other authorized information to predict your preference characteristics through algorithmic models, match products, services, or other information that you may be interested in, and sort the products, services, or other information displayed to you. To meet your diverse needs, we will introduce diversified recommendation techniques in the sorting process to expand the recommended content and avoid excessive concentration of similar content. We will also push you commercial advertisements and other information that you may be interested in on the YMS platform and other third-party applications based on your preference characteristics, or send you commercial short messages.</font></span><span
      style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">At the same time, we strive to ensure your privacy experience through the following measures:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. If you want to delete your browsing and search records, you can choose to delete them yourself;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. If you do not want to accept the commercial advertisement we sent you, you can unsubscribe or close it by replying to the SMS prompt or other methods we provide.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">3） Provide you with product subscription and supplier collection functions</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">During your browsing of our website or client, you can choose to subscribe to products and/or services that interest you, and establish a following relationship with merchants/brands that interest you. During your use of the above functions, we will collect service log information, including your favorite records and following relationships, for the purpose of achieving the above functions and other clearly stated purposes.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can view or manage your favorite information in Product Management.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">4） Help you complete order placement and order management</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">When you order specific goods and/or services in our products and/or services, we will generate an order for you to purchase the goods and/or services through the system. During the ordering process, you need to provide at least the name, address, and contact phone number of the recipient. For some special types of goods and services, you also need to provide other necessary information for the goods or services. For example, when cross-border transactions are involved, you need to provide the recipient's ID information to complete customs clearance.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">At the same time, the order will include information about the goods and/or services you purchased, specific order number, order creation time, amount you should pay, and your remarks. We collect this information to help you successfully complete your transaction, ensure your transaction security, facilitate your inquiry of order information, provide customer service and after-sales service, and other clearly stated purposes.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can order goods and/or services for others through the YMS platform. You need to provide the aforementioned personal information of the actual recipient and ensure that you have obtained the consent of the actual recipient.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">To facilitate your understanding, query, and management of order information, we will collect order information generated during your use of our services for display and convenience in order management.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can provide additional information, including other contact numbers and delivery times, to ensure accurate delivery of the goods or services.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can view and manage your order information through 'My Orders'.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">5） Help you complete the payment</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">To complete order payment, we provide you with multiple payment methods. When you use balance payment, you need to accept and authorize your identity information to open a third-party payment account provided by the YMS platform, recharge the balance account, and select balance payment. The third-party payment methods provided by the platform are independent privacy policies and will be provided to you when you apply for activation.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">In order to keep us informed and confirm your payment progress and status in a timely manner, and to provide you with after-sales and dispute resolution services, you agree that we can collect payment progress related information from the trading partner you choose or other financial institutions you choose.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">6） Help complete the delivery of goods or services to you</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">To ensure that the goods and/or services you purchase can be delivered and provided smoothly, safely, and accurately, we will disclose the delivery information related to the order to the logistics information system and technical services provided to the YMS platform, and the relevant delivery information will be synchronized with the corresponding logistics delivery subject based on the selection of the goods and/or service provider. You are aware and agree that the corresponding logistics delivery entity will inevitably be informed of and use your delivery information for the purpose of completing the delivery.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">For the purpose of providing after-sales and dispute resolution services to you, we need to promptly learn and confirm the delivery progress and status. You agree that we can collect information related to the delivery progress from logistics related service providers.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">7） Customer service and dispute resolution</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">When you contact us or apply for handling disputes during sales, after-sales, or disputes, in order to ensure the security of your account and system, we need you to provide necessary personal information to verify your membership status.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">For the convenience of contacting you, helping you solve the problem as soon as possible, or recording the handling plan and results of related issues, we may save your communication, communication/call records, and related content (including account information, order information, other information you provide to prove relevant facts, or contact information you leave) with us. If you provide inquiries, complaints, or suggestions regarding specific orders, we will use your account information and order information.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">In order to provide services and improve service quality, we may also use your other information, including the relevant information you provided when contacting customer service, and the questionnaire response information you sent us when participating in the survey.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">8） Providing you with security</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font
      face="微软雅黑">In order to fulfill the legal obligation to ensure the security of e-commerce transactions, improve the security of your use of services provided by us and our partners, protect the personal and property security of you or other users or the public from infringement, better prevent phishing websites, fraud, network vulnerabilities, computer viruses, network attacks, and network intrusion risks, and more accurately identify violations of laws, regulations, or relevant agreements and rules of the YMS platform, We embed an application security SDK developed by a third-party company in the application to collect your device information, service log information, and may use or integrate your membership information, transaction information, device information, service log information, as well as third-party company information Partners obtain your authorization or legally shared information (among which we may collect MAC addresses, IMEIs, and other device identifiers for risk verification during application backend operation) to comprehensively assess your account and transaction risks, conduct identity verification, detect and prevent security incidents, and take necessary recording, auditing, analysis, and disposal measures in accordance with the law.</font></span><span
      style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">9） Other additional services provided to you</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Additional services based on system permissions</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">In order to provide you with more convenient, high-quality, and personalized products and/or services, and strive to improve your experience, we may enable system permissions to collect and use your personal information in the following additional services provided to you. If you do not agree to enable the relevant permissions, it will not affect your use of the basic services of the YMS platform, but you may not be able to obtain the user experience brought by these additional services.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can decide whether these permissions can be turned on or off at any time at your own discretion.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Please note that if you enable any permission, it means that you authorize us to collect and use relevant personal information to provide you with corresponding services. If you close any permission, it means that you have cancelled the authorization. We will no longer continue to collect and use relevant personal information based on the corresponding permission, nor can we continue to provide you with the corresponding services for that permission. Your decision to close permissions will not affect the collection and use of information based on your authorization.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Cookies and similar technology services</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Cookies and similar device information identification technology are commonly used in the internet. When you use our services, we may use relevant technology to send one or more cookies or anonymous identifiers (hereinafter referred to as "cookies") to your device to collect, identify, and store information about your access and use of this product. We promise not to use cookies for any purpose other than those stated in this privacy policy. We mainly use cookies to ensure the safe and efficient operation of our products and services. They can help us confirm the security status of your account and transactions, troubleshoot related anomalies such as crashes and delays, and help you avoid repeating the steps and processes of filling out forms and entering search content.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">At the same time, we may use cookies to showcase information or features that may interest you and optimize your advertising choices. Most browsers provide users with the function of clearing browser cache data. You can perform corresponding data clearing operations, modify your acceptance of cookies, or reject our cookies. You may not be able to use cookies dependent services or corresponding features due to these modifications.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、 Provide information externally</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">1） Sharing</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We will not share your personal information with companies, organizations, or individuals other than YMS platform service providers, except for the following situations:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Sharing necessary for fulfilling legal obligations: We may share your personal information in accordance with laws and regulations, litigation, dispute resolution, or as required by administrative and judicial authorities in accordance with the law, as well as other legal obligations.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Sharing with your consent: After obtaining your explicit consent, we will share your personal information with other parties.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3. Sharing necessary for the conclusion and performance of the contract to which you are a party: If you purchase goods or services through the YMS platform, we will, based on your choice, share the necessary information related to the transaction in your order information with the relevant suppliers of the goods or services to meet your transaction, delivery, and after-sales service needs.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4. Sharing with affiliated companies: In order to facilitate us and our affiliated companies to provide you with some services, we will provide you with products and services based on the YMS platform account, recommend information that you may be interested in, identify abnormal member accounts, and protect the personal and property security of YMS platform affiliated companies or other users or the public from infringement. Your personal information may be shared with our affiliated companies and/or their designated service providers. We will only share necessary personal information and are bound by the stated purposes in this policy. If we share your sensitive personal information or if our affiliated companies change the purpose of using and processing personal information, we will seek your authorization and consent again.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Please note that the information you voluntarily share or even publicly share when using our services may involve personal information or even sensitive personal information of you or others. Please consider and make a decision more carefully.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">2） Entrusted processing</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We may authorize authorized partners to process your personal information in order to provide certain services or perform functions on our behalf. We will only authorize our partners to process your information for the legitimate, legitimate, necessary, specific, and clear purposes stated in this policy statement. Authorized partners will only have access to the information they need to perform their duties, and we will require them through an agreement not to use this information for any other purpose beyond the scope of the authorization. If authorized partners use your information for purposes we have not delegated, they will separately obtain your consent.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Currently, our authorized partners include the following types:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Suppliers, service providers, and other partners. We entrust information to suppliers, service providers, and other partners who support our business, including technical infrastructure services provided by us, analysis of the usage of our services, providing customer service, payment facilitation, or conducting academic research and research. When you choose to "become a distributor" or "become a supplier", your name, contact information, platform account information Your registration time and recent login time are provided to the service provider so that they can contact you to help you become our member.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">3） Transfer</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">If we need to transfer personal information due to merger, division, dissolution, or bankruptcy, we will inform you of the recipient's name or contact information. The recipient will continue to fulfill this policy and other legal obligations. If the recipient changes the original processing purpose and method, they will obtain your consent again.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">4） Public disclosure</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We will only publicly disclose your personal information in the following circumstances:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Based on your voluntary choice and other separate consent, we may publicly disclose your personal information;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. If we determine that you have violated laws and regulations or seriously violated YMS platform related agreements and rules, or to protect the personal and property safety of YMS platform users or the public from infringement, we may disclose personal information about you, including relevant violations and measures taken by YMS platform against you.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、 Your Rights</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can access and manage your information through the following methods, and we will respond to your request in compliance with legal and regulatory requirements:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">1） Review, Correction, and Supplement</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You have the right to access, correct, and supplement your information through the following methods:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Log in to the YMS platform, enter the "My Subscriptions" homepage, enter "Account Management Basic Information" and "Account Management Security Information";</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Log in to the YMS platform website and choose to contact customer service.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">2） Delete</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can contact customer service through the YMS platform to delete some of your information or apply to cancel your account to delete all of your information.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">In the following situations, you can request us to delete personal information by contacting customer service:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. If our handling of personal information violates laws and regulations;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. If we collect and use your personal information without your explicit consent;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3. If our handling of personal information seriously violates our agreement with you;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4. If our processing purpose has been achieved, cannot be achieved, or is no longer necessary to achieve the processing purpose;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">5. If we stop providing products or services, or if the shelf life has expired.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">If we decide to respond to your deletion request, we will also notify the parties who obtained your personal information from us as much as possible and require them to delete it in a timely manner (unless otherwise specified by laws and regulations, or if these parties have independently obtained your authorization).</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">When you or we assist you in deleting relevant information, due to applicable laws and security technology limitations, we may not be able to immediately delete the corresponding information from the backup system. We will securely store your personal information and restrict any further processing until the backup can be cleared or anonymized.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">3） Change authorization scope</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can change or revoke the scope of your authorization to collect and process your personal information by:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can make modifications through customer service, but please note that for some types of personal information, such as information necessary to implement the basic functions of the YMS platform or information necessary for us to fulfill our obligations under laws and regulations, we may not be able to respond to your request to change the scope of authorization. After you withdraw your authorization, we will no longer process the corresponding personal information. But your decision to revoke your authorization will not affect the personal information processing we have previously conducted based on your authorization.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">4） Account cancellation</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can apply to cancel your account by contacting customer service.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">After you voluntarily cancel your account, we will stop providing you with products or services and delete or anonymize your personal information in accordance with applicable laws.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">5） Constrained Information System Automatic Decision Making</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">In certain business functions, we may only make decisions based on non manual automatic decision-making mechanisms, including algorithms. If these decisions affect your legitimate rights and interests, you can contact us through customer service.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">6） Respond to your above request</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">For the above requests made to us by you or your potential guardians, close relatives, and other authorized parties, as well as your relevant personal information rights under the laws of the People's Republic of China and other applicable laws, you can contact us through customer service. We will provide a response within 15 days.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">To ensure the security of your account and personal information, when you make the above request to us, we may first verify your identity (such as increasing account verification, requesting you to provide a written request, or other reasonable means), and then process your request.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We do not charge any fees for your reasonable requests in principle, but for repeated requests that exceed reasonable limits, we will charge a certain fee as appropriate. We may refuse requests for information that is not directly related to your identity, information that is repeatedly applied for without reasonable reasons, or requests that require excessive technical means (such as developing new systems or fundamentally changing current practices), pose risks to the legitimate rights and interests of others, or are unrealistic.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">5、 Storage of Information</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">1） Storage period</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We will only retain your personal information for the period necessary to achieve the purposes stated in this policy, unless there are mandatory retention requirements by law, such as the Electronic Commerce Law of the People's Republic of China, which requires the retention of goods and service information and transaction information for no less than three years from the date of transaction completion.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We determine the storage period of personal information mainly based on the following standards:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Complete the transaction purpose related to you, maintain corresponding transaction and business records to respond to your possible inquiries or complaints;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Ensure the safety and quality of our services provided to you;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3. Do you agree to a longer retention period;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4. Are there any other special agreements or legal regulations regarding the retention period.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">After exceeding the retention period, we will delete or anonymize your personal information in accordance with applicable laws.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">2） Storage location</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">The personal information collected and generated during our operations within the People's Republic of China will be stored within the territory of China. In the following circumstances, we will provide your personal information to potential overseas entities after fulfilling our legal obligations:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. The applicable laws have clear provisions;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Obtain your separate consent;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3. It is necessary for you, as a party, to enter into and perform cross-border e-commerce transactions and other types of contracts.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">In response to the above situations, we will protect your personal information security in accordance with this policy in accordance with the law.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（</font><font face="微软雅黑">3） Storage security</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. Technical measures for data protection</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We have taken reasonable and feasible security measures that comply with industry standards to protect your information from unauthorized access, public disclosure, use, modification, damage or loss. For example, we will use encryption technology to improve the security of personal information, and exchange data between your browser and server is protected by SSL protocol encryption. We provide HTTPS protocol secure browsing method; We will use trusted protection mechanisms to prevent malicious attacks on personal information; We will deploy access control mechanisms to ensure that only authorized personnel can access personal information.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Organizational Measures for Data Protection Management</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We have established an advanced data security management system centered around the data lifecycle in the industry, which enhances the security of personal information from the dimensions of organizational construction, system design, personnel management, and product technology. We continuously enhance employees' awareness of the importance of protecting personal information through training courses and exams.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3. Response to personal information security incidents</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font
      face="微软雅黑">If our physical, technical, or management protective facilities are damaged, resulting in unauthorized access, public disclosure, tampering, or destruction of information, resulting in damage to your legitimate rights and interests, we will promptly initiate emergency plans and take reasonable and necessary measures to minimize the impact on you personally. If a personal information security incident occurs, we will also inform you of the basic situation and possible impact of the security incident in accordance with legal and regulatory requirements, the disposal measures we have taken or will take, suggestions for you to independently prevent and reduce risks, and remedial measures for you. We will inform you through text messages, phone calls, push notifications, and other reasonable channels. If it is difficult to inform you one by one, we will adopt reasonable and effective methods to publish the announcement. At the same time, we will also report the handling of personal information security incidents in accordance with regulatory requirements.</font></span><span
      style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4. Prevention of account security risks</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">When using YMS platform services for online transactions, you inevitably have to disclose your personal information, such as contact information or address, to the counterparty or potential counterparty. In addition, you can also establish communication, exchange information, or analyze content with third parties through our services. Please properly protect your personal information and only provide it to others when necessary. Do not trust other trading tools to avoid information theft or even telecommunications network fraud.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">If you are concerned about the leakage of your personal information, especially your account or password, please contact our customer service immediately so that we can take corresponding measures based on your application. You can also modify your login password independently in "Account Management Security Information".</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">6、 Policy updates</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">To provide you with a better product and service experience, we continuously strive to improve our products, services, and technology. When new services and business processes change, we may update our privacy policy to inform you of specific changes. But without your explicit consent, we will not limit your rights under this policy. We will publish any changes made to this policy on a dedicated page.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">For significant changes, we will also provide more significant notifications (including notification through the YMS platform or pop-up notifications provided to you).</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">The significant changes referred to in this policy include but are not limited to:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. The business model of the product has undergone significant changes. For example, the purpose of processing personal information, the type of personal information processed, and the way personal information is used;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. Changes in the subject of privacy policies caused by business adjustments, transaction mergers and acquisitions, and changes in the original processing purpose and method of the new subject recipient;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3. Changes in the main objects of personal information sharing or public disclosure;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4. Significant changes have occurred in the user's personal information rights and their exercise methods;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">5. When there are changes in the contact information and complaint channels of the person in charge of handling personal information protection;</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">6. When the personal information protection impact assessment report indicates that the product has a significant impact on personal rights and interests.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We will also archive the old version of this policy on a dedicated page of the YMS platform for your reference.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">7、 Protection of minors</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Children are not allowed to create their own user accounts without the consent of their parents or guardians.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">We assume that you have corresponding civil capacity in e-commerce activities. If you are a minor, we request that your parents or other guardians carefully read this policy and the "Children's Personal Information Protection Rules and Guardian Instructions", and use our services or provide information to us with the consent of your parents or other guardians.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">For the collection of personal information of minors using our products or services with the consent of our parents or guardians, we will only use, share, transfer or disclose this information when permitted by laws and regulations, explicitly agreed by our parents or guardians, or necessary to protect minors. If we find ourselves collecting personal information about a child without obtaining verifiable parental consent in advance, we will try to delete the relevant data as soon as possible.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">If you are a parent or other guardian of a child, please pay attention to whether the child has used our services after obtaining your authorized consent. If you have any questions about children's personal information, please contact our customer service.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">8、 Contact Us</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">You can contact us through the following methods, and we will respond to your request within 15 days:</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1. If you have any questions, opinions or suggestions regarding the content of this policy, you can contact us through YMS platform customer service (400-0600-405);</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2. You can send your complaint or report materials by mail (please indicate that they are related to the YMS platform and provide specific factual information). Our mailing address is: [6th Floor, Building C1, Nanshan Zhiyuan, Xueyuan Avenue, Nanshan District, Shenzhen]. It should be noted that we may not respond to questions unrelated to this policy or your personal information rights.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">If you are not satisfied with our response, especially if you believe that our personal information processing behavior has damaged your legitimate rights and interests, you can also seek a solution by filing a lawsuit with the court with jurisdiction in the defendant's residence.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Appendix: Relevant Definitions</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS platform: Refers to the website and client of the YMS platform (with the domain name yms. com).</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS Platform Service Provider: Refers to Shenzhen Yunmai Supply Chain Management Co., Ltd., an internet information and software technology service provider of the YMS platform.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Personal information: Various information recorded electronically or otherwise related to identified or identifiable natural persons, excluding information processed anonymously.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Sensitive personal information: Sensitive personal information refers to personal information that, once leaked or illegally used, can easily cause harm to the personal dignity or personal and property safety of natural persons, including biometrics, religious beliefs, specific identities, medical health, financial accounts, trajectory information, as well as personal information of minors under the age of fourteen.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Children: Refers to minors under the age of fourteen.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Device information: including device identifiers (IMEI, IDFA, Android ID, MAC, OAID, IMSI, and other device related information), application information (application crash information, notification switch status, application installation list, and other application related information), device parameters and system information (device type, device model, operating system, and hardware related information), Device network environment information (IP address, WiFi information, base station information, and other network related information). Based on the actual collection situation of the product.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Service log information: including browsing records, click to view records, search query records, favorites, adding to shopping cart, transactions, after-sales, following and sharing information, publishing information, as well as IP address, browser type, telecom operator, language used, visit date and time, and web page records you visited.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Deidentification: refers to the process of processing personal information so that it cannot identify a specific natural person without the use of additional information.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Anonymization: refers to the process in which personal information cannot be identified as a specific natural person and cannot be restored after processing.</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
  </div>
</template>

<script>

export default {
  name: "PrivacyPolicyUS",
  data() {
    let v = this;
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "等线 Light";
}

@font-face {
  font-family: "微软雅黑";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 1;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 2;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 18.0000pt;
}

h3 {
  mso-style-name: "标题 3";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 13.0000pt;
  margin-bottom: 13.0000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 3;
  line-height: 173%;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 16.0000pt;
  mso-font-kerning: 1.0000pt;
}

h5 {
  mso-style-name: "标题 5";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 14.0000pt;
  margin-bottom: 14.5000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 5;
  line-height: 156%;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 14.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeading8 {
  mso-style-name: "标题 8";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 12.0000pt;
  margin-bottom: 3.2000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 8;
  line-height: 133%;
  font-family: '等线 Light';
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: Calibri;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.16 {
  font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
}

span.17 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.18 {
  font-family: 'Times New Roman';
  mso-ansi-font-style: italic;
}

span.19 {
  font-family: 'Times New Roman';
  font-size: 10.5000pt;
}

span.20 {
  font-family: 宋体;
  mso-ansi-font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

span.21 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.22 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.23 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 16.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.24 {
  font-family: '等线 Light';
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.25 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 14.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  margin: 0pt;
  margin-bottom: .0001pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  margin: 0pt;
  margin-bottom: .0001pt;
  border-bottom: 1.0000pt solid windowtext;
  mso-border-bottom-alt: 0.7500pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: center;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
}

p.MsoCommentSubject {
  mso-style-name: 批注主题;
  mso-style-parent: 批注文字;
  mso-style-next: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.3000pt 841.9000pt;
  layout-grid: 15.6000pt;
  mso-header-margin: 42.5500pt;
  mso-footer-margin: 49.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>