
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';
// 拣货状态
import {setConfigCharacters} from "@/utils/common";

export const YMS_STATUS_LIST = [
  {value: 1, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001214')},
  {value: 2, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001215')},
  {value: 3, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001216')},
  {value: 4, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001217')},
  {value: 5, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001218')},
  {value: 6, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001219')},
  {value: 7, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001220')},
  {value: 8, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')},
];

// 出库单状态
export const YMS_PACKAGE_INFO_STATUS = [
  {value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001222')},
  {value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001223')},
  {value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001214')},
  {value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001224')},
  {value: 5, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001225')},
  {value: 6, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001226')},
  {value: 7, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001227')},
  {value: 8, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')}
];

export const PICK_STATUS_LIST = [
  {
    value: 1,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001228')
  },
  {
    value: 2,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001202')
  },
  {
    value: 3,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001217')
  },
  {
    value: 4,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001203')
  },
  {
    value: 5,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001229')
  },
  {
    value: 21,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001230')
  },
  {
    value: 99,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001231')
  }
];

export const YMS_PACKAGE_STATUS = [
  {
    value: 0,
    title: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001201')}`
  },
  {
    value: 9,
    title: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001202')}`
  },
  {
    value: 10,
    title: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001203')}`
  },
  {
    value: 11,
    title: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001204')}`
  },
  {
    value: 12,
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001232')
  }
];

export const YMS_SUBPACKAGE_STATUS = [
  {value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001214')},
  {value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001224')},
  {value: 5, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001233')},
  {value: 6, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001226')},
  {value: 7, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001204')},
  {value: 8, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')}
];

// listing inputType
export const LISTING_INPUT_TYPE = [
  {
    value: '1',
    label: 'text'
  }, {
    value: '2',
    label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000465')
  }, {
    value: '3',
    label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001234')
  }, {
    value: '4',
    label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000464')
  }, {
    value: '5',
    label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001235')
  }
];


// 规则是否类型
export const RULE_TYPE_IS = ['order-service-A044', 'order-service-A045', 'order-service-A042', 'order-service-A040', 'order-service-A035', 'order-service-A036', 'order-service-A037', 'order-service-A038', 'order-service-A047', 'order-service-A048', 'order-service-A046',
  'CORE-YCCR-045', 'DISTRIBUTOR-DCCR-A024', 'DISTRIBUTOR-DCCR-A028'
];

// 规则单个input 类型
export const RULE_TYPE_INPUT = ['CORE-YCCR-003', 'CORE-YCCR-004', 'DISTRIBUTOR-DCCR-A005', 'DISTRIBUTOR-DCCR-A004'];

// 规则 两个checkbox input 类型
export const RULE_TYPE_TCI = [
  'CORE-YCCR-014', 'CORE-YCCR-015', 'CORE-YCCR-016', 'CORE-YCCR-017', 'CORE-YCCR-018', 'CORE-YCCR-019', 'CORE-YPDR-008', 'CORE-YPDR-006', 'CORE-YPDR-009', 'CORE-YCCR-010', 'CORE-YCCR-013', 'DISTRIBUTOR-DCCR-A012', 'DISTRIBUTOR-DCCR-A016', 'DISTRIBUTOR-DCCR-A017', 'DISTRIBUTOR-DCCR-A018', 'DISTRIBUTOR-DCCR-A019', 'DISTRIBUTOR-DCCR-A020', 'DISTRIBUTOR-DCCR-A021', 'DISTRIBUTOR-DCCR-A022'];

// 平台订单状态
export const PLATFORM_ORDER_STATUS = [
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095'), value: 'null'},
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001222'), value: 1},
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001236'), value: 2},
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001237'), value: 3},
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001238'), value: 4},
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001227'), value: 5},
  {label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221'), value: 6},
];

// 三方平台订单状态
export const THIRH_PARTY_PLATFORM_ORDER_STATUS = [
  {value: 0, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001239')},
  {value: 1, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001240')},
  {value: 2, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001227')},
  {value: 21, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001241')},
  {value: 3, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001090')},
  {value: 31, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001242')},
  {value: 4, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')},
  {value: 41, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001243')}
];

// 供应商--出库单类型
export const OUTBOUNDTYPELIST = [ // 1:销售出库 2:云卖中心仓备货 3:三方仓备货 null:全部
  {value: 0, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {value: 1, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001244')},
  {value: 2, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001245')},
  {value: 3, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001246')},
  {value: 4, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001247')},
];

// 云卖订单状态
export const CLOUDSALESORDERSTATUS = [
  {selected: true, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001222')},
  {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001223')},
  {selected: false, value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001214')},
  {selected: false, value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001224')},
  {selected: false, value: 5, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001225')},
  {selected: false, value: 6, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001226')},
  {selected: false, value: 7, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001227')},
  {selected: false, value: 8, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')}
];

// 销售状态
export const SALESSTATUS = [
  {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000408')},
  {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000351')},
  {selected: false, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000407')},
];

// 审核状态
export const AUDITSTATUS = [
  {selected: true, value: 'null', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001248')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001249')},
  {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001250')},
  {selected: false, value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000665')}
];

// 费用类型
export const EXPENSETYPE = [
  {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001251')},
  {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001252')},
  {selected: false, value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001253')},
  {selected: false, value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001254')},
  {selected: false, value: 5, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001255')},
  {selected: false, value: 6, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001256')},
  {selected: false, value: 7, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001257')},
  {selected: false, value: 8, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001258')}
];

// 启用停用状态
export const ENABLEDISABLESTATUS = [
  {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000507')},
  {selected: false, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000508')}
];

//面单类型
export const EACESHEETTYPE = [
  {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 'LOGIS', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000108')},
  {selected: false, value: 'YMS', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000109')},
  {selected: false, value: 'NONE', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000110')},
  {selected: false, value: 'MIX', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000111')}
];

// 异常状态
export const ABNORMALSTATE = [
  {selected: true, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001259')},
  {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000348')}
];

// 商品状态
export const COMMODITYSTATUS = [
  {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000351')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000408')},
  {selected: false, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000831')},
  {selected: false, value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000832')}
];

// 费用流水状态
export const EXPENSEFLOWSTATUS = [
  {selected: true, value: null, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001260')},
  {selected: false, value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001261')}
];

// 注册来源
export const REGISTER_SOURCE = [
  {value: 0, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {value: 1, title: 'TONGTOOL'},
  {value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001262')},
  {value: 4, title: 'Shopify'},
  {value: 5, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001263')},
  {value: 6, title: 'H5'}
];

// 结算状态(供应商系统) 1 待审核状态 2 待确认 3 等待收款/付款 4 已收款/已付款 5 作废
export const SUPPLIER_SETTLEMENTSTATUS = [
  {selected: true, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001264')},
  {selected: false, value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001265')},
  {selected: false, value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001266')}
];
// 结算状态(运营系统)
export const YMS_SETTLEMENTSTATUS = [
  {selected: false, value: 'null', title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {selected: false, value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001249')},
  {selected: true, value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001267')},
  {selected: false, value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001268')},
  {selected: false, value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001269')}
];
// 语种对应
export const mappingLang = {
  EN: 'usName',  // 英文
  FRA: 'frName', // 法文
  GER: 'deName', // 德文
  SPN: 'esName', //西班牙
  NL: 'nlName',  // 荷兰语
  PLN: 'plName', // 波兰
  PT: 'ptName',  // 葡萄牙
  IT: 'itName',  // 意大利
  CN: 'cnName'   // 中文
};

const fixPath = 'https://www.yms.com/yms-distributor-service/filenode/s'; // 固定写死
export const videos = {
  1: fixPath + alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001270'),
  2: fixPath + alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001271'),
  3: fixPath + alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001272'),
  4: fixPath + alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001273'),
  5: fixPath + alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001274'),
  6: fixPath + alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001275'),
};
export const DELIVERYORDERTYPE = [
  // {value: 1, title: '销售出库'},
  {value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001245')},
  {value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001246')},
  {value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001247')}
];

// 多语种名称
export const MULTILINGUALNAMESLIST = {
  CN: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001276'),
  US: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001277'),
// {type: 'frName', name: '法语'},
// {type: 'deName', name: '德语'},
// {type: 'esName', name: '西班牙语'},
// {type: 'nlName', name: '荷兰语'},
// {type: 'plName', name: '波兰语'},
// {type: 'ptName', name: '葡萄牙语'},
// {type: 'itName', name: '意大利语'}
}

// 消息模板
export const INFORMATIONTYPELIST = [
  {
    value: 1,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000348'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001278'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001279'),
    dynamicParamList: [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001280')], // 动态参数
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001281')
  },
  {
    value: 2,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001282'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001283'),
    dynamicParamList: ['X'], // 动态参数
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001284')
  },
  {
    value: 3,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001285'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001286'),
    dynamicParamList: ['X'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001287'),
  },
  {
    value: 4,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001288'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001289'),
    dynamicParamList: ['X'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001290')
  },
  {
    value: 5,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001291'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001292'),
    dynamicParamList: ['X'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001293')
  },
  {
    value: 6,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001294'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001295'),
    dynamicParamList: ['X'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001296')
  },
  {
    value: 7,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000097'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001297'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001298'),
    dynamicParamList: [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001280')],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001299')
  },
  {
    value: 8,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000351'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001300'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001301'),
    dynamicParamList: [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000302'), 'SPU/SKU'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001302')
  },
  {
    value: 9,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001303'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001304'),
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001305'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001303'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001306')
  },
  {
    value: 10,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001307'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001308'),
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001309'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001307'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001310')
  },
  {
    value: 11,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001311'),  // 触发条件的文案
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001312'), // 消息类型名称
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001313'), // 消息的标题
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001311'),
    dynamicParamList: [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000819')],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001314')
  },
  {
    value: 12,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001315'),  // 触发条件的文案
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001316'), // 消息类型名称
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001316'), // 消息的标题
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001315'),
    dynamicParamList: [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001317')],
    messageTemplate: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001205')}<span class="highlight_color">${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001206')}</span>${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001207')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001208')}`
  },
  {
    value: 13,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001318'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001319'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001318'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001320')
  },
  {
    value: 14,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001321'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001322'),
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001321'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001323')
  },
  {
    value: 15,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001324'),
    name: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001209')}`,
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001324'),
    dynamicParamList: [`${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000357')}`],
    messageTemplate: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001210')}<span class="highlight_color">【${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001211')}</span>${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001212')}`
  },
  {
    value: 16,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001325'),  // 触发条件的文案
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001326'), // 消息类型名称
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001327'), // 消息的标题
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001325'),
    dynamicParamList: [alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001328'), alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000357')],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001329')
  },
  {
    value: 17,
    text: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001330'),
    name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001331'),
    title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001331'), // 消息的标题
    triggerConditionTxt: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001330'),
    dynamicParamList: ['SPU'],
    messageTemplate: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001332')
  },
];

// 供应商--订单列表--出库单状态
export const SUPPLIEROUTPACKAGEORDERSTATUS = [
  {value: 1, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001214')},
  {value: 2, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001218')},
  {value: 3, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001333')},
  {value: 4, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001204')},
  {value: 5, title: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001213')}`},
  {value: 6, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001334')},
  {value: 7, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001231')},
  {value: 8, title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001226')}
]

// 平台订单类型
export const PLATFORMORDERTYPE = [
  {value: 'null', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {value: 'GENERAL', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001335')},
  {value: 'TEMU_S', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001336')},
  {value: 'TEMU_P', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001337')},
  {value: 'ONE_STOP_SERVICE', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001338')},
]

// IOSS 类型
export const IOSSTYPE = [
  {value: 1, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001339')},
  {value: 2, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001340')},
  {value: 3, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001341')},
]

// 翻译之后的云卖订单状态(订单轨迹)
export const YMSORDERSTATUSLIST = [
  {sort: 0, value: 'null', key: 'null', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000095')},
  {sort: 1, value: 0, key: 'createdTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001222')},
  {sort: 2, value: 13, key: 'createdTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001342')},
  {sort: 3, value: 1, key: 'printTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001343')},
  {sort: 4, value: 2, key: 'pickingFinishTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001344')},
  {sort: 5, value: 3, key: 'packingTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001345')},
  {sort: 6, value: 4, key: 'supperBoxedTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001346')},
  {sort: 7, value: 5, key: 'despatchTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001347')},
  {sort: 8, value: 11, key: 'allottedTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001348')},
  {sort: 9, value: 6, key: 'allottedTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001214')},
  {sort: 10, value: 7, key: 'pickedTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001218')},
  {sort: 11, value: 8, key: 'changedLabelTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001333')},
  {sort: 12, value: 9, key: 'boxedTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001349')},
  {sort: 13, value: 10, key: 'shipedTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001350')},
  {sort: 14, value: 12, key: 'cancelledTime', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')},
];

export const ATTRIBUTEMULTILINGUALNAME = [
  {key: 'cnName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000476')},
  {key: 'usName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001351')},
  {key: 'ukName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001352')},
  {key: 'auName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001353')},
  {key: 'euName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001354')},
  {key: 'frName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000481')},
  {key: 'deName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000482')},
  {key: 'esName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000483')},
  {key: 'nlName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000484')},
  {key: 'plName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000485')},
  {key: 'ptName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000486')},
  {key: 'itName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000487')},
  {key: 'roName', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000488')},
];

export const ATTRIBUTEVALUEDATA = [
  {key: 'cnValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000476')},
  {key: 'usValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001351')},
  {key: 'ukValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001352')},
  {key: 'auValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001353')},
  {key: 'euValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001354')},
  {key: 'frValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000481')},
  {key: 'deValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000482')},
  {key: 'esValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000483')},
  {key: 'nlValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000484')},
  {key: 'plValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000485')},
  {key: 'ptValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000486')},
  {key: 'itValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000487')},
  {key: 'roValue', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000488')},
];

export const PRODUCTTYPES = [
  {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000628'), value: 1},
  {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000630'), value: 2},
  {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000629'), value: 3},
  {title: 'POD', value: 4},
];

export const VISIBLEAFTERLOGIN = alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001355');

// 无站点的平台
export const NOSITEPLATFORMLIST = [
  'wish', 'joom', '1688', 'aliexpress', 'fruugo', 'ozon', 'shopify', 'temu', 'tiktok',
  'walmart', 'aliexpressChoice', 'alibaba', 'temu_choice'
];

