export default {
  state: {
    warehouseId: null, // 仓库ID
    carrierName: '',
    formInlineInfo: ''
  },
  mutations: {
    warehouseId(state, data) {
      state.warehouseId = data;
    },
    carrierName(state, data) {
      state.carrierName = data;
    },
    carrierAccountId(state, data) {
      state.carrierAccountId = data;
    },
    formInlineInfo(state, data) {
      state.formInlineInfo = data;
    }
  },
  actions: {}
};
