/**
 * 这里存放商城公共引入
 * @author gan
 * @date: 2021/1/26 18:07
 */
import Vue from 'vue';
import Vuex from 'vuex';
import viewDesign from 'view-design';
import locale from 'view-design/dist/locale/en-US';
import '#@/static/css/animate.min.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'view-design/dist/styles/iview.css';
import 'viewerjs/dist/viewer.css';
import "@/assets/styles/base.less";
import "@/assets/styles/theme.less";
import "@/assets/styles/config.less";
import "@/assets/styles/shopping/shopping.less";
import "@/assets/styles/icon/platform-icon.less";
import VueViewer from 'v-viewer';

Vue.use(Vuex);
let useViewLocal = {
  locale: {}
};

if (localStorage.getItem('lang')) {
  if (localStorage.getItem('lang') === 'en-US') {
    useViewLocal.locale = locale;
  } else {
    useViewLocal = null;
  }
}

Vue.use(viewDesign, {
  ...useViewLocal
});
Vue.use(VueViewer);
