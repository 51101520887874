// @all-no-translate
<template>
  <div class="Section0" style="layout-grid:15.6000pt;">
    <h2 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-ansi-font-weight:bold;
font-size:14.0000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">服务条款</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-ansi-font-weight:bold;
font-size:14.0000pt;mso-font-kerning:0.0000pt;"></span></b></h2>
    <p class=MsoNormal align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;text-align:center;line-height:18.0000pt;
mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.0000pt;
mso-font-kerning:1.0000pt;"><font face="微软雅黑">最后更新日期：</font><font face="微软雅黑">2</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.0000pt;
mso-font-kerning:1.0000pt;">023</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.0000pt;
mso-font-kerning:1.0000pt;"><font face="微软雅黑">年</font><font face="微软雅黑">1月</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.0000pt;
mso-font-kerning:1.0000pt;">28</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.0000pt;
mso-font-kerning:1.0000pt;"><font face="微软雅黑">日</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.0000pt;
mso-font-kerning:1.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
line-height:18.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">欢迎</font><font face="微软雅黑">“您”（本协议项下的用户为供应商，以下均简称“您”、“用户”或称“会员”）与“深圳云卖供应链管理有限公司”（下称“云卖供应链”）共同签署本《服务条款》协议（下称“本协议”），本协议阐述之条款和条件适用于您使用云卖供应链基于互联网，以包含YMS平台网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。 </font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">自本协议发布之日起，</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台各处所称</font><font face="微软雅黑">“服务条款”均指本协议。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>一、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">接受协议</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
line-height:18.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);
mso-list:l14 level2 lfo2;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-ansi-font-style:normal;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">本协议内容包括协议正文及所有云卖供应链已经发布或将来可能发布并采取合理途径通知的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l14 level2 lfo2;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-ansi-font-style:normal;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您在点击同意本协议之前，应当认真阅读本协议。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体标识，您应重点阅读。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您对协议有任何疑问，可向</font><font face="微软雅黑">YMS平台客服咨询。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l14 level2 lfo2;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-ansi-font-style:normal;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>1.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序，或以任何方式进入</font><font face="微软雅黑">YMS平台并使用服务即表示您已充分阅读、理解并同意接受本协议的条款和条件。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序并停止使用服务。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>二、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">定义</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">YMS平台：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">指云卖供应链主办运营的为注册用户提供商品信息展示及商品在线交易居间服务和管理的网络交易平台，域名为</font><font face="微软雅黑">y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">ms.com</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">指</font><font face="微软雅黑">“深圳云卖供应链管理有限公司”，系YMS平台的经营者。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链服务：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">指云卖供应链基于互联网，以包含</font><font face="微软雅黑">YMS平台、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务（下称“服务”）</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.4<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">YMS平台规则：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">指在</font><font face="微软雅黑">YMS平台消息中心内已发布及后续发布的全部规则、解读、公告等内容以及云卖供应链在帮助中心等渠道发布的各类规则、实施细则、产品流程说明、公告等。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.5<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">是指通过</font><font face="微软雅黑">YMS平台直接为分销商提供商品及相应服务的法律主体，该主体与分销商之间为实际采销关系，主要包括生产制造商、经销商、品牌代理商和其他中介商等。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.6<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">分销商：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">是指在</font><font face="微软雅黑">YMS平台采购商品的法律主体。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">&nbsp;</span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.7<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">分销商品：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">是指在</font><font face="微软雅黑">YMS平台上发布的并供分销商下单采购的商品，以下简称</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">“商品”；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.8<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">采购订单：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">是指分销商在</font><font face="微软雅黑">YMS平台上向供应商采购商品并完成订单款项支付后自动生成的交易订单。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.9<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">销售订单：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">是指分销商通过第三方平台或分销商的其他销售渠道将在</font><font face="微软雅黑">YMS平台上采购的商品销售给终端消费者或其他购买者所产生的交易订单。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.10<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">日：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">指日历日或日历天。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.11<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">工作日：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">指根据本协议履行地所在国家法律法规规定的除法定节假日以外的工作时间。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l2 level2 lfo3;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>2.12<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">关联方：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">是指直接或间接被一方所控制、与该一方处于共同控制之下、或控制该方的任何实体、企业、公司、联营体、合营企业、股份公司、合伙、个人、接管人和清算人。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>三、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">协议范围</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l13 level2 lfo4;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>3.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">签约主体</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">本协议由您与云卖供应链共同缔结，本协议对您与云卖供应链具有同等合同效力。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l13 level2 lfo4;"><![if !supportLists]><span style="font-family:微软雅黑;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;
mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>3.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">补充协议</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">由于互联网高速发展，您与云卖供应链签署的本协议列明的条款并不能完整罗列并覆盖您与云卖供应链所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链法律声明、隐私政策、</font><font face="微软雅黑">YMS平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用云卖供应链服务，视为您同意上述补充协议。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>四、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">账户注册与使用</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">用户资格</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您确认，在您完成注册程序或以其他云卖供应链允许的方式实际使用服务时，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的相应后果，且云卖供应链有权终止向您提供服务并注销您的账户。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您代表一家公司或其他法律主体在</font><font face="微软雅黑">YMS平台注册，则您声明和保证，您有权使该公司或该法律主体受本协议的约束。 </font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">此外，您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">账户注册</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">在您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或在您按照激活页面提示填写信息、阅读并同意本协议且完成全部激活程序后，或您以其他云卖供应链允许的方式实际使用云卖供应链服务时，您即受本协议的约束。您可以使用您提供或确认的邮箱、手机号码或者云卖供应链允许的其它方式作为登录手段进入</font><font face="微软雅黑">YMS平台。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.4<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">由于您的账户关联您的信息及</font><font face="微软雅黑">YMS平台商业信息，您的账户仅限您本人使用。未经云卖供应链同意，您直接或间接授权第三方使用您账户或获取您账户项下信息的行为无效。如云卖供应链根据您账户的使用行为等情况判断您账户的使用可能危及您的账户安全及/或YMS平台信息安全的，云卖供应链可拒绝提供相应服务或终止本协议。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">账户转让</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经云卖供应链同意，并符合</font><font face="微软雅黑">YMS平台规则规定的账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">除此外，您的账户不得以任何方式转让，否则</font><font face="微软雅黑">YMS平台有权追究您的违约责任，且由此产生的相应责任均由您承担。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.4<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">实名认证</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">为使您更好地使用</font><font face="微软雅黑">YMS平台的各项服务，保障您的账户安全，云卖供应链可要求您按国家法律法规的规定完成实名认证。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
line-height:18.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);
mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.5<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">人员对接</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您自注册</font><font face="微软雅黑">YMS平台账户之日起，应委派您的工作人员及时有效与YMS平台人员对接相关工作，如您委派的工作人员变更的，您应提前五个工作日书面告知YMS平台新对接人员联系方式，如您未告知YMS平台上述事项或您因此造成账户管理不善的，相关责任及费用由您自行承担；如YMS平台发现您存在上述事项的，有权通知您整改，由此产生的您或第三方或YMS平台的责任及损失均由您自行承担。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
line-height:18.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);
mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.6<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">不活跃账户回收</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您的账户同时符合以下条件，您的</font><font face="微软雅黑">YMS平台登录名可能被注销，不能再登陆YMS平台，所有YMS平台服务将同时终止：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）未按照国家相关法律法规以及云卖供应链要求通过实名认证；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）连续</font><font face="微软雅黑">6个月未使用您的邮箱、手机或云卖供应链认可的其他方式和密码登录过YMS平台；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）不存在未到期的有效业务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
line-height:18.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);
mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.7<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">注册信息管理</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.5<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">在完成注册或激活流程时，您应当按</font><font face="微软雅黑">YMS平台相应页面的提示准确完整地提供并及时更新您的信息，以使之始终保持真实、及时，完整和准确。在国家法律法规有明确规定要求云卖供应链作为平台服务提供者必须对用户（如分销商、供应商等）的信息进行核实的情况下，云卖供应链将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.6<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如云卖供应链按您最后一次提供的信息与您联系未果、您未按云卖供应链的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及云卖供应链造成的全部损失与不利后果。云卖供应链可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部云卖供应链服务，除法律明确规定外云卖供应链对此不承担责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.7<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您设置的账户登录名及昵称不得违反国家法律法规及</font><font face="微软雅黑">YMS平台规则的相应管理规定，否则云卖供应链可回收您的登录名及昵称，并按YMS平台规则进行相应的处理。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l5 level2 lfo5;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.8<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">账户安全</font> </span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.8<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您的账户为您自行设置并由您保管，云卖供应链任何时候均不会主动要求您提供您的账户密码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开</font><font face="微软雅黑">YMS平台。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，云卖供应链并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.9<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">除云卖供应链存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、采购产品、订购服务及披露信息等）负责。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l5 level3 lfo6;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>4.1.10<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如发现任何未经授权使用您账户登录</font><font face="微软雅黑">YMS平台或其他可能导致您账户遭窃、遗失的情况，建议您立即通知云卖供应链。您理解云卖供应链对您的任何请求采取行动均需要合理时间，且云卖供应链应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除云卖供应链存在法定过错外，云卖供应链不承担责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>五、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商的资格</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l0 level2 lfo7;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>5.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您如按</font><font face="微软雅黑">YMS平台网站注册页面提示完成注册后，经YMS平台审核通过的，可以获得YMS平台登录账户。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">特此提示：该登录账户仅为普通账户，您通过该普通账户仅能浏览有限的</font><font face="微软雅黑">YMS平台网页信息，不具享有YMS平台提供的主要功能及服务。如您需深入使用YMS平台的主要功能及服务的，您应按照YMS平台提示，尽快完善用户信息，录入注册用户资料，并将上述真实、完整、有效的信息资料及时提交YMS平台进行审核。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l0 level2 lfo7;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>5.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您拟取得</font><font face="微软雅黑">YMS平台供应商资格的，则您应依据YMS平台提示信息，向YMS平台提交您的基本信息及注册用户资料，由YMS平台对您的供应商资格申请进行初步审核，如您所提供的信息资料未能符合YMS平台的供应商资格的，YMS平台有权不授予您供应商资格。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您取得供应商资格的，则您可以在取得资格之日起通过在线浏览</font><font face="微软雅黑">YMS平台的分销商需求涉及的商品所涉及的相关信息，如您获得YMS平台授权的，则可将供应商的相关商品信息数据上传并发布到YMS平台。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">特此提示：如您将上述信息、数据发布至</font><font face="微软雅黑">YMS平台的，如未能获得Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台授权或超过授权范围的，或未遵守您与</font><font face="微软雅黑">YMS平台及/或分销商约定，或您提供的商品信息数据图片等存在虚假、错误、不完整、违反法律法规或YMS平台规则、相关条款、或商品及/或服务被投诉、处罚等事项，上述任一事项发生时，则YMS平台均有权要求您删除对外刊登或发布的相关信息、图片、数据等。您自行承担上述风险及责任。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l0 level2 lfo7;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>5.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您取得供应商资格后，依据</font><font face="微软雅黑">YMS平台相关条款约定，可以通过YMS平台从事相应的活动，主要包括提供商品信息、上架产品、安排货物运输、提供售后等。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">上述采购订单确立后，由供应商自行指定或委托</font><font face="微软雅黑">YMS平台指定其他物流服务商负责安排发货事宜，并将分销商所采购的商品配送至分销商指定的收货人处。供应商在YMS平台操作、与云卖供应链签署的协议涉及的每个采购订单、销售订单、其他相关活动均受本协议的约束。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l0 level2 lfo7;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>5.4<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">为适应法律法规、政策变更及</font><font face="微软雅黑">YMS平台业务发展需求，您同意YMS平台有权要求您变更或修改需要提交给YMS平台的基本信息及作为供应商的相应资料，同时YMS平台有权调整供应商准入标准。据此，在新供应商评估标准及平台信息披露要求变更前，对于已经成为YMS平台的注册用户、供应商的，YMS平台将给予该用户、供应商一定的合理期限（具体以YMS平台要求为准），供注册用户、供应商进行信息补录、资格完善。注册用户、供应商在此同意并承诺在上述合理期限届满前自行办理满足最新的YMS平台注册用户、供应商的资格要求及标准，否则，本协议将在相应合理期限届满时自动终止，该供应商不再享有YMS平台供应商资格及其相关权益。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>六、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商的权利与义务</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">特殊提示：供应商对在</font><font face="微软雅黑">YMS平台上展示、销售、售后的商品/服务应具有自主销售或代理销售资质或符合其他相关要求，不存在侵犯或超出生产方、品牌方、销售代理等的权益及授权，上述商品应为正品及新品，且符合YMS平台公示规则、相关条款、供应商与YMS平台、云卖供应链签署的协议约定；同时，供应商在YMS平台展示、销售的商品均视为供应商同意并有权授权分销商进行再代理、出售、转让、授权给第三方销售及/或使用。如供应商未能满足上述事项的，上述责任由供应商自行承担，如给YMS平台、分销商、其他第三方造成损失的，供应商应承担上述赔偿。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您具有</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的供应商资格后，在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台规则体系内有权使用</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台提供的信息服务及交易居间管理服务，依据实际从事的商业业务，享有包括但不限于商品信息浏览、商品信息数据包下载、商品信息管理、确认交易订单等，具体以</font><font face="微软雅黑">YMS平台提供的服务为准。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商充分理解并同意在使用</font><font face="微软雅黑">YMS平台相关服务时，YMS平台有权向供应商收取服务费，具体收费标准将通过YMS平台进行发布或供应商与云卖供应链协议约定。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商承诺并保证在使用</font><font
      face="微软雅黑">YMS平台提供的服务在线销售商品的过程中应遵守诚实信用的原则，遵守本协议以及平台规则，尊重YMS平台的经营模式及正当利益，不在交易过程中从事不正当竞争行为或采取不正当手段妨碍YMS平台的正常经营活动及正常秩序或损害YMS平台的利益，不从事与在线交易无关的行为，不会通过虚构或隐瞒交易事实、规避或恶意利用YMS平台或其他任何第三方交易平台（包括但不限于第三方支付平台，例如PayPal, Payoneer等）的交易规则干扰或妨害平台的经营秩序及利益，不会利用YMS平台或其他第三方交易平台的疏忽或规则漏洞谋取或恶意谋取利益或侵害YMS平台的利益，亦不会通过不正当方式销售虚假的商品、获取市场营销优惠及增值服务权益等不当利益，一经发现或YMS平台有合理理由怀疑供应商存在或可能存在前述行为的，YMS平台有权取消供应商享有的优惠或权益并要求供应商返还已经取得的所有优惠或权益（如有），同时有权要求供应商向YMS平台支付不低于人民币20万元/次的违约金，如违约金不足以弥补YMS平台因此遭受损失的，供应商还应另行赔偿YMS平台所遭受的损失。YMS平台有权在供应商未结算的货款中自动扣除前述违约金或赔偿金且不对供应商承担任何责任，不足部分应由供应商在收到YMS平台书面通知后五个工作日内补足，逾期未支付的，供应商应按照每日万分之五的标准支付逾期罚息。</font></span></b><span
      style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.4<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商在</font><font face="微软雅黑">YMS平台刊登或发布商品信息时，应遵守与YMS平台、分销商、其他消费者或其他购买者之间的相关约定。如果供应商发布的商品信息内容超出或违反供应商与YMS平台及/或分销商、其他第三方授权范围或提供的信息范围或YMS平台规则、本协议、分销商与供应商及/或YMS平台之间的约定的，上述任一事项产生相关责任与风险均由供应商自行承担。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">特此提示：分销商与供应商或第三方平台或其他销售渠道或消费者或其他购买者之间的任何约定都不得对抗本协议以及</font><font face="微软雅黑">YMS平台规则、相关条款。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.5<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商在</font><font face="微软雅黑">YMS平台销售、刊登、发布商品时，应及时同步更新YMS平台的实际商品库存，并与分销商、消费者或其他购买者做好售前确认及售后服务。供应商同意并知悉无论任何原因导致分销商、消费者或其他购买者确认销售订单或拍下商品后库存无货而产生的纠纷，均应由供应商自行承担风险与责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.6<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商有义务自行了解商品销售地或分销商或消费者或购买者所在地或商品运输途径地有关商品运输、销售的相关法律法规、当地政策，并承诺严格予以遵守上述法律法规、当地政策。如供应商的商品在销售、运输、售后过程中存在违反上述法律法规、当地政策的，相关责任与风险由供应商自行承担，供应商充分理解、同意并不可撤销地承诺不要求</font><font face="微软雅黑">YMS平台承担责任及费用。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.7<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商承诺遵守</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的交易流程及相关交易规则，不会采取任何手段或措施，包括明示或暗示分销商进行场外交易或通过其他方式转移其可以通过</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台获取商品来源的采购订单，以扰乱</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的正常经营活动或损害</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的利益。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.8<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商充分理解并同意，供应商同意并不可撤销地授权并委托</font><font face="微软雅黑">YMS平台为供应商提供采购订单商品及其他相应服务，具体以YMS平台确认为准。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.9<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商如提供物流运输安排的，应及时确认订单收货地址、收货人联系方式。如供应商提供一件代发服务的，供应商将按照分销商在</font><font face="微软雅黑">YMS平台上填写的消费者或其他购买者的收货地址进行配送。如分销商下单后改变收货地址或收货人联系方式的，供应商应及时查看相关信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">特此提示：</font><font face="微软雅黑">YMS平台仅提供相关信息服务，具体以平台开放的服务权限为准，供应商应自行处理上述物流运输服务，如物流运输过程中出现货损、丢失、逾期清点、验收、收货及其他风险、赔偿及费用的，供应商应进行相关管理，且及时告知YMS平台，如造成分销商、消费者或其他采购者、其他主体损失的，供应商应向上述主体承担赔偿责任。YMS平台不就上述事宜承担责任及风险。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.10<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商应知悉并同意如已委托</font><font face="微软雅黑">YMS平台从事售后代理服务的，应获得YMS平台确认。如出现售后问题，分销商向YMS平台咨询的，YMS平台将按照YMS平台网站上公布的售后规则、服务条款以及其他规则代表供应商为分销商提供售后服务。供应商已查阅并同意YMS平台上公布的售后服务条款并认可其全部内容。供应商充分理解并同意，YMS平台为其提供售后服务不代表YMS平台是商品的实际供应商。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.11<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">YMS平台将尽力对供应商在YMS平台发布的商品进行品控管理。如因供应商提供的商品存在的问题导致分销商被行政监督管理部门、第三方投诉、索赔或受到行政处罚的，则供应商应向分销商、消费者、购买方、第三方利益相关方或行政管理部门承担责任。如上述赔付确属供应商原因导致的，供应商承担就分销商所承担的责任以及所作的赔付向分销商承担全部赔偿责任，YMS平台有权协助分销商对具体供应商发起索赔或追偿程序。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.12<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商充分理解，并同意如</font><font face="微软雅黑">YMS平台将采购订单涉及的相关收货人地址及联系方式、订单信息、数据等均向供应商披露的。供应商应做好保密措施，如因上述数据披露可能导致问题及风险，供应商应承担该等风险及责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l8 level2 lfo8;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>6.13<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商对其商品信息有义务进行管理及规范，包括但不限于就修改商品品类、品名、标题、价格、参数、功能、库存数量和描述、库存清零、下架、删除等造成的商品信息变动，届时，</font><font face="微软雅黑">YMS平台将第一时间同步最新的商品信息，尽最大努力确保商品信息的准确性。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>七、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">服务及规范</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">YMS平台应当在现有技术上维护网站的正常运行，并努力促进和改进技术，使</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoIns"><ins cite="mailto:夏坤" datetime="2023-01-30T17:36"><font face="微软雅黑">供应商</font></ins></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoDel"><del cite="mailto:夏坤" datetime="2023-01-30T17:36"><font face="微软雅黑">分销商</font></del></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">在</font><font face="微软雅黑">YMS平台上的交易活动顺利进行。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">一般情况下，</font><font face="微软雅黑">YMS平台不作为供应商直接或间接提供商品信息及商品</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoDel"><del cite="mailto:夏坤" datetime="2023-01-30T17:37"><font face="微软雅黑">，也不作为分销商采购供应商的商品</font></del></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">。</font><font face="微软雅黑">YMS平台上的商品信息均由供应商发布，YMS平台不能完全确保供应商及相关信息的真实性、准确性及合法性以及交易双方的履约能力等，但YMS平台将采取措施对供应商进行监督评估，同时将评估结果予以公布</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoDel"><del cite="mailto:夏坤" datetime="2023-01-30T17:38"><font face="微软雅黑">，分销商应通过自己的谨慎判断确定商品信息的真实性、准确性及合法性</font></del></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">。如因供应商原因导致分销商在</font><font face="微软雅黑">YMS平台上采购供应商的商品时遭受损失的，YMS平台有义务协助分销商对供应商进行追偿。特殊情况下，如YMS平台作为供应商的代理人在YMS平台上代销分销商品的，YMS平台将尽最大努力确保供应商及相关商品信息的真实性、准确性及合法性，因商品质量问题给分销商造成损失的，分销商应优先选择供应商承担责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">YMS平台有权对供应商在YMS平台上发布的商品及销售活动进行监管，如YMS平台发现或有合理理由怀疑供应商违反其与YMS平台之间的服务协议或平台规则或从事相关违法违规活动的，YMS平台将视情况严重程度对该供应商采取相应的处罚措施，包括但不限于对供应商的销售资格加以限制或取缔，关闭其注册账户甚至对供应商予以清退。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoDel"><del cite="mailto:夏坤" datetime="2023-01-30T17:43"><font face="微软雅黑">如</font><font face="微软雅黑">YMS平台对供应商采取如上处罚措施而影响到分销商提交的采购订单之履行的，YMS平台应及时告知分销商并协助分销商采取相应措施减少损失，分销商承诺不会通过任何形式追究YMS平台的责任。</font></del></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.4<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商与分销商由于</font><font face="微软雅黑">YMS平台上发生的采购行为而产生的纠纷，属于YMS平台受理范围的，任何一方可请求YMS平台进行调处。YMS平台本着公平、公正、公开的原则，对供应商或分销商提交的证据进行形式审查，以普通大众的认知水准及日常经验进行判断，并据此作出纠纷责任的归属认定及纠纷调处的结论。供应商或分销商任何一方如对调处决定不满意的，有权采取其他争议处理途径解决纠纷。但通过其它纠纷处理途径未取得最终决定前，仍应先履行YMS平台的调处决定。YMS平台可配合提供必要的信息支持以促进纠纷的解决。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.5<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商对其商品信息进行管理，包括但不限于修改商品标题、价格、类目属性、库存数量和描述等时导致商品信息发生变动，或者供应商对商品库存清零、下架或删除商品而导致商品对分销商同步下架的，</font><font face="微软雅黑">YMS平台将第一时间同步最新的商品信息，尽最大努力确保商品信息的准确性。由于商品信息的数量极其庞大，虽然YMS平台会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，YMS平台网页显示的信息可能会有一定的滞后性或差错，对此情形您完全知悉理解并承诺不会通过任何形式追究YMS平台的责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.6<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">因网上交易平台的特殊性，</font><font face="微软雅黑">YMS平台没有义务对所有分销商、供应商、平台上其他用户的信息、数据、交易行为、订单以及相关事项进行事先审查。本协议的签署、分销商、供应商的资格审核通过并不意味着YMS平台对分销商、供应商、用户的身份、资质、履约能力、从事的交易行为、平台活动、上传、发布的信息的完整性、真实性、准确性、合法性等承担任何明示或暗示的保证或担保等责任。但如果其他第三方通知YMS平台，认为某个具体分销商或具体交易事项可能存在重大问题的，YMS平台以普通非专业交易者的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为具有违法或不当性质的，YMS平台有权根据不同情况选择中止或终止对该分销商、供应商提供服务或清退。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.7<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoDel"><del cite="mailto:夏坤" datetime="2023-01-30T17:46"><font face="微软雅黑">YMS平台有权对分销商的基本信息、商品选购及下载信息及采购交易信息进行查阅，有权对分销商的交易行为进行监督和管理，如发现存在任何问题或怀疑，YMS平台有权向分销商发出询问或要求改正的通知，或者直接作出中止或终止本协议的处理结果且不对分销商承担任何责任。</font></del></span></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span class="msoDel"><del cite="mailto:夏坤" datetime="2023-01-30T17:46"></del></span></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.8<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">供应商不得在</font><font face="微软雅黑">YMS平台上销售以下产品及/或提供以下服务：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）国家禁止或限制的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）侵犯他人知识产权或其它合法权益的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）</font><font face="微软雅黑">YMS平台规则、公告、通知或各平台与您单独签署的协议中已明确说明不适合在YMS平台上销售及/或提供的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l4 level2 lfo9;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>7.9<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">依法纳税是每一个公民、企业应尽的义务，您应对销售额</font><font face="微软雅黑">/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>八、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">费用</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l15 level2 lfo10;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>8.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链为</font><font face="微软雅黑">YMS平台向您提供的服务付出了大量的成本，除YMS平台明示的收费业务外，云卖供应链向您提供的服务目前是免费的。如未来云卖供应链向您收取合理费用，云卖供应链会采取合理途径并提前通过法定程序以本协议约定的有效方式通知您，确保您有充分选择的权利。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l15 level2 lfo10;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>8.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您因进行交易、向云卖供应链获取有偿服务以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>九、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">责任限制</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l3 level2 lfo11;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>9.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链依照法律规定履行基础保障义务，但对于下述不可抗力或其他第三人因素原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，云卖供应链并不承担相应的违约责任：</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）在云卖供应链已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l3 level2 lfo11;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>9.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链仅向您提供本协议约定之服务，您了解</font><font face="微软雅黑">YMS平台上的信息系用户自行发布，且可能存在风险和瑕疵。云卖供应链将通过依照法律规定建立相关检查监控制度尽可能保障您在Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的合法权益及良好体验。同时，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">鉴于</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台作为网络交易平台无法逐一审查产品及</font><font face="微软雅黑">/或服务的信息，无法逐一审查交易所涉及的产品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断，如发现存在危害信息和行为，请您及时同Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台进行联系和投诉，</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台将对有害信息和行为进行处理。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l3 level2 lfo11;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>9.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您理解并同意，在争议调处服务中，</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的客服并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台不保证争议调处决定一定符合您的期望，除法律明确规定及存在故意或重大过失外，</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台对争议调处决定不承担责任。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">用户信息的保护及授权</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l6 level2 lfo12;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>10.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">个人信息的保护</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用云卖供应链提供的服务时，您同意云卖供应链按照在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上公布的隐私政策收集、存储、使用、披露和保护您的个人信息。云卖供应链希望通过隐私政策向您清楚地介绍云卖供应链对您个人信息的处理方式，因此云卖供应链建议您完整地阅读隐私政策（</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(255,0,0);
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><a :href="privacyPolicyUrl" target="_blank"><font face="微软雅黑">点击此处</font></a></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">或点击</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台首页底部链接），以帮助您更好地保护您的隐私权。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l6 level2 lfo12;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>10.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">非个人信息的保证与授权</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l6 level3 lfo13;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>10.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链可对您发布的信息依法或依本协议进行删除或屏蔽。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l6 level3 lfo13;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>10.1.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">您应当确保您所发布的信息不包含以下内容：</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）违反国家法律法规禁止性规定的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）欺诈、虚假、不准确或存在误导性的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（六）存在可能破坏、篡改、删除、影响</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台任何系统正常运行或未经授权秘密获取</font><font face="微软雅黑">YMS平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">（七）其他违背社会公共利益或公共道德或依据相关</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台协议、规则的规定不适合在</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上发布的。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l6 level3 lfo13;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>10.1.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">对于您提供、发布及在使用</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，均不会因您的上传、发布等行为发生知识产权、肖像权等权利的转移。除非</font><font face="微软雅黑">YMS平台另行明确说明，在法律允许的范围和</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">法律规定的保护期限内，您免费授予云卖供应链及其关联方获得非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）权利及可再授权给其他第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l6 level3 lfo13;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>10.1.4<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">为方便您使用</font><font face="微软雅黑">YMS平台等其他相关服务，您授权YMS平台将您在账户注册和使用YMS平台服务过程中提供、形成的信息传递给YMS平台等其他相关服务提供者，或从YMS平台等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十一、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">用户的违约及处理</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level2 lfo14;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">违约认定</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">发生如下情形之一的，视为您违约：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）使用云卖供应链服务时违反有关法律法规规定的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）违反本协议或本协议补充协议（即本协议第</font><font face="微软雅黑">3.2条）约定的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，云卖供应链可在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台规则中约定违约认定的程序和标准。如：云卖供应链可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level2 lfo14;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">违约处理措施</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo15;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上发布的信息构成违约的，云卖供应链可根据相应规则立即对相应信息进行删除、屏蔽处理或对您的产品进行下架、删除、监管。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo15;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上实施的行为，或虽未在</font><font face="微软雅黑">YM</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">S</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上实施但对</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台及其用户产生影响的行为构成违约的，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链可依据相应规则对您中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，云卖供应链可关闭您的账户，终止向您提供服务。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo15;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">当您违约的同时存在欺诈、售假、盗用他人账户等特定情形或您存在危及他人交易安全或账户安全风险时，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链会依照您行为的风险程度对您在</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上的账户采取取消收款、资金止付等强制措施。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo15;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.4<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台上予以公示。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level2 lfo14;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">赔偿责任</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo16;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您的行为使云卖供应链及</font><font face="微软雅黑">/或其关联方遭受损失，您应根据本协议履行赔偿责任，您需赔偿云卖供应链及/或其关联方的因此遭受的损失（如有）</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">包括：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）支付的合理律师费、诉讼费、消除影响所支出的必要费用；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）因行政处罚、司法裁判、法定标准范围内的调解等对外支出的罚款、违约金或赔偿金；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）商誉损失、合理预期利益损失（如会员的流失、支出减少、消费频次降低及店铺经营者收入减少等），具体金额可以第三方独立合理分析、云卖供应链根据大数据合理分析及其他计算收益、损失的合理方法所得出的相关估算标准作为计算依据；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（四）因此遭受的其他损失。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo16;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您的行为使云卖供应链及</font><font face="微软雅黑">/或其关联方遭受第三人主张权利，云卖供应链及/或其关联方可在对第三人承担金钱给付等义务后就全部损失向您追偿。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo16;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.3<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如因您的行为使得第三人遭受损失或您怠于履行调处决定，您授权并同意云卖供应链及</font><font face="微软雅黑">/或其关联方出于保护社会公共利益或保护其他用户合法权益目的，可自您的Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台账户中划扣相应款项进行支付。如您的</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台账户余额不足以支付相应款项的，您同意委托云卖供应链使用自有资金代您支付上述款项（但云卖供应链并无此义务），您应当返还该部分费用并赔偿因此造成云卖供应链的全部损失。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo16;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.4<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您同意云卖供应链自您的</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台账户中划扣相应款项支付上述赔偿款项。如您</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台账户中的款项不足以支付上述赔偿款项的，云卖供应链及</font><font face="微软雅黑">/或关联方可直接抵减您在云卖供应链及/或其关联方其它协议项下的款项及/或权益，并可继续追偿。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo16;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.5<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链因故意或重大过失违约导致您利益受损的，云卖供应链将以您的直接实际损失为计算依据向您履行赔偿责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level2 lfo14;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.4<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">特别约定</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo17;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您向云卖供应链及</font><font face="微软雅黑">/或其关联方的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">发生上述情形的，云卖供应链可按照</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台规则进行相应处理，并经提前通知后终止与您的所有合作并向您收取违约金及</font><font face="微软雅黑">/或赔偿金，该等金额以云卖供应链因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo17;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您因严重违约导致云卖供应链终止本协议时，出于维护平台秩序及保护其他用户合法权益的目的，云卖供应链及</font><font face="微软雅黑">/或其关联方可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议约定的有效方式通知您。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l1 level3 lfo17;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>11.1.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">如云卖供应链与您签署的其他协议及云卖供应链及</font><font face="微软雅黑">/或其关联方与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则云卖供应链出于维护平台秩序及保护其他用户合法权益的目的，可在收到指令时中止甚至终止协议，并以本协议约定的有效方式通知您。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十二、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">协议的变更</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l7 level2 lfo18;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>12.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链可根据国家法律法规变化及维护市场秩序、保护用户合法权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称</font><font face="微软雅黑">“变更事项”）将通过法定程序并以本协议约定的有效通知方式通知您。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l7 level2 lfo18;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>12.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">如您不同意变更事项，您有权于变更事项确定的生效日前联系云卖供应链反馈意见。如反馈意见得以采纳，云卖供应链将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用云卖供应链服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用云卖供应链服务，则视为您同意已生效的变更事项。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十三、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">有效通知</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l12 level2 lfo19;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>13.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">有效联系方式</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您在注册成为</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台用户，并接受云卖供应链服务时，您应该向云卖供应链提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被有效联系的状态。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您在注册</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台用户时生成的用于登陆</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台接收站内信、系统消息等即时信息的会员账户（包括子账户），也作为您的有效联系方式。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的产品</font><font face="微软雅黑">/服务广告信息、促销优惠等商业性信息；</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">您如果不愿意接收此类信息，您有权通过云卖供应链提供的相应的退订功能进行退订。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l12 level2 lfo19;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>13.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">通知的送达</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l12 level3 lfo20;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>13.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账户发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l12 level3 lfo20;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>13.1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">对于在</font><font face="微软雅黑">YMS平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十四、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">协议终止</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level2 lfo21;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">协议终止的情形</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level3 lfo22;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您有权通过以下任一方式终止本协议：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）在满足</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台要求的账户注销条件时您通过网站自助服务注销您的账户的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）变更事项生效前您停止使用云卖供应链服务并明示不愿接受变更事项的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）您明示不愿继续使用云卖供应链服务，且符合</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台要求的终止条件的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level3 lfo22;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">出现以下情况时，云卖供应链可以本协议约定的有效方式通知您终止本协议：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）您的实名认证身份无效、不再合法存续或无法有效核实；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）您违反本协议约定，云卖供应链依据违约条款终止本协议的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（三）您盗用他人账户、发布违禁信息、欺诈、售假、扰乱市场秩序、采取不正当手段牟利等行为，云卖供应链依据</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台规则对您的账户予以关闭的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（四）除上述情形外，因您多次违反</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台规则相关规定且情节严重，云卖供应链依据</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台规则对您的账户予以关闭的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（五）您的账户被云卖供应链依据本协议回收的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（六）您在</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台有欺诈、发布或销售假冒伪劣</font><font face="微软雅黑">/侵权产品、侵犯他人合法权益或其他严重违法违约行为的；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（七）云卖供应链基于合理理由相信您的行为可能会使您、云卖供应链及</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台用户等相关方发生严重损害或产生法律责任；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（八）其它应当终止服务的情况。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level2 lfo21;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.2<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">协议终止后的处理</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level3 lfo23;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.1.1<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">本协议终止后，除法律有明确规定外，云卖供应链无义务向您或您指定的第三方披露您账户中的任何信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level3 lfo23;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.1.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">本协议终止后，云卖供应链仍享有下列权利：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（一）继续保存您留存于</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台的本协议</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">第十二条</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">所列的各类信息；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">（二）对于您过往的违约行为，云卖供应链仍可依据本协议向您追究违约责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l9 level3 lfo23;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>14.1.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">本协议终止后，对于您在本协议存续期间产生的交易订单，云卖供应链可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十五、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">链接</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">平台或第三者均可提供与其他万维网网站或资源的链接。由于云卖供应链并不控制该等网站和资源，您理解并同意，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">云卖供应链并不对该等外在网站或资源的可用性负责，且不认可该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料，也不对其等负责或承担任何责任。您进一步理解和同意，对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，除非法律明确规定应由云卖供应链承担责任情形外，云卖供应链均不承担责任。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-left:21.0000pt;text-indent:-21.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l10 level1 lfo1;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>十六、<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">法律适用、管辖及其他</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"></span></b></p>
    <p class=p style="margin-bottom:5.0000pt;margin-left:0.0000pt;text-indent:0.0000pt;
mso-pagination:widow-orphan;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l11 level2 lfo24;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>16.1<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律；如无相关法律规定的，则应参照商业惯例和（或）行业惯例。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l11 level2 lfo24;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>16.2<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">您与云卖供应链仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇佣与被雇佣或特性授权与被授权关系。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l11 level2 lfo24;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>16.3<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">除双方另有约定外，您同意云卖供应链因经营业务需要有权将本协议项下的权利义务就部分或全部进行转让，并采取合理途径提前通过法定程序以本协议约定的有效方式通知您。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l11 level2 lfo24;"><![if !supportLists]><span style="font-family:微软雅黑;font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>16.4<span>&nbsp;</span></span></span><![endif]><b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"><font face="微软雅黑">因本协议或云卖供应链服务所引起或与其有关的任何争议，由云卖供应链与您协商解决。协商不成时，任何一方均可向被告住所地人民法院提起诉讼。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=p style="margin-top:5.0000pt;margin-bottom:5.0000pt;margin-left:0.0000pt;
text-indent:0.0000pt;mso-pagination:widow-orphan;line-height:18.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);mso-list:l11 level2 lfo24;"><![if !supportLists]><span style="font-family:微软雅黑;font-size:10.5000pt;mso-font-kerning:0.0000pt;"><span style='mso-list:Ignore;'>16.5<span>&nbsp;</span></span></span><![endif]><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"><font face="微软雅黑">倘若本协议任何条款被裁定为无效或不可强制执行，该项条款应被撤销，而其余条款应予遵守和执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。云卖供应链未就您或其他人士的某项违约行为采取行动，并不表明云卖供应链撤回就任何继后或类似的违约事件采取行动的权利。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:0.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;font-size:10.5000pt;
mso-font-kerning:1.0000pt;">&nbsp;</span></p>
  </div>
</template>

<script>

import {getHrefPath} from "@/utils/common";

export default {
  name: "supplierServiceTermsCN",
  data() {
    let v = this;
    return {}
  },
  computed: {
    // 隐私政策
    privacyPolicyUrl() {
      let url = '/index.html#/privacyPolicy';
      let service = '/yms-shopping-service';
      return getHrefPath(url, service);
    },
  },
  methods: {}
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "微软雅黑";
}

@list l0:level1 {
  mso-level-start-at: 5;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l0:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l0:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l0:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l0:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l0:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l0:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l0:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l0:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l1:level1 {
  mso-level-start-at: 11;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l1:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l1:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l1:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l1:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l1:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l1:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l1:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l2:level1 {
  mso-level-start-at: 2;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
  mso-ansi-font-weight: normal;
  mso-ansi-font-style: normal;
}

@list l2:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l2:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l3:level1 {
  mso-level-start-at: 9;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l3:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l3:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l3:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l3:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l3:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l3:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l3:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l3:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l4:level1 {
  mso-level-start-at: 7;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l4:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l4:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l4:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l4:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l4:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l4:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l4:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l4:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l5:level1 {
  mso-level-start-at: 4;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
  mso-ansi-font-weight: normal;
  mso-ansi-font-style: normal;
}

@list l5:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l5:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l5:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l5:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l5:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l5:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l5:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l5:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l6:level1 {
  mso-level-start-at: 10;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l6:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l6:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l6:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l6:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l6:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l6:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l6:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l6:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l7:level1 {
  mso-level-start-at: 12;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l7:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l7:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l7:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l7:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l7:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l7:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l7:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l7:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l8:level1 {
  mso-level-start-at: 6;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l8:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l8:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l8:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l8:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l8:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l8:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l8:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l8:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l9:level1 {
  mso-level-start-at: 14;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l9:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l9:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l9:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l9:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l9:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l9:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l9:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l9:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l10:level1 {
  mso-level-number-format: chinese-counting-thousand;
  mso-level-suffix: tab;
  mso-level-text: "%1、";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.0000pt;
  text-indent: -21.0000pt;
  font-family: 宋体;
}

@list l10:level2 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%2)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 42.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level3 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%3.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 63.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%4.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 84.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level5 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%5)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 105.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level6 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%6.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 126.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%7.";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 147.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level8 {
  mso-level-number-format: alpha-lower;
  mso-level-suffix: tab;
  mso-level-text: "%8)";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 168.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l10:level9 {
  mso-level-number-format: lower-roman;
  mso-level-suffix: tab;
  mso-level-text: "%9.";
  mso-level-tab-stop: none;
  mso-level-number-position: right;
  margin-left: 189.0000pt;
  text-indent: -21.0000pt;
  font-family: 'Times New Roman';
}

@list l11:level1 {
  mso-level-start-at: 16;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l11:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l11:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l11:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l11:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l11:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l11:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l11:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l11:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l12:level1 {
  mso-level-start-at: 13;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l12:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l12:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l12:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l12:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l12:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l12:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l12:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l12:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l13:level1 {
  mso-level-start-at: 3;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l13:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
  mso-ansi-font-weight: normal;
  mso-ansi-font-style: normal;
}

@list l13:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l13:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l13:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l13:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l13:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l13:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l13:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l14:level1 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
  mso-ansi-font-weight: normal;
  mso-ansi-font-style: normal;
}

@list l14:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l14:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l15:level1 {
  mso-level-start-at: 8;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l15:level2 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l15:level3 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 70.9000pt;
  text-indent: -28.3500pt;
  font-family: 宋体;
}

@list l15:level4 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l15:level5 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l15:level6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l15:level7 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l15:level8 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l15:level9 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l5:level1 lfo6 {
  mso-level-start-at: 4;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
  mso-ansi-font-weight: normal;
  mso-ansi-font-style: normal;
}

@list l5:level2 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l5:level3 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l5:level4 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l5:level5 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l5:level6 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l5:level7 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l5:level8 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l5:level9 lfo6 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l6:level1 lfo13 {
  mso-level-start-at: 10;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l6:level2 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l6:level3 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l6:level4 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l6:level5 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l6:level6 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l6:level7 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l6:level8 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l6:level9 lfo13 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l1:level1 lfo15 {
  mso-level-start-at: 11;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l1:level2 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level3 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level4 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l1:level5 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l1:level6 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l1:level7 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l1:level8 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l1:level9 lfo15 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l1:level1 lfo16 {
  mso-level-start-at: 11;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l1:level2 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level3 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level4 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l1:level5 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l1:level6 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l1:level7 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l1:level8 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l1:level9 lfo16 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l1:level1 lfo17 {
  mso-level-start-at: 11;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l1:level2 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level3 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l1:level4 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l1:level5 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l1:level6 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l1:level7 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l1:level8 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l1:level9 lfo17 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l12:level1 lfo20 {
  mso-level-start-at: 13;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l12:level2 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l12:level3 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l12:level4 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l12:level5 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l12:level6 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l12:level7 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l12:level8 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l12:level9 lfo20 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l9:level1 lfo22 {
  mso-level-start-at: 14;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l9:level2 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l9:level3 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l9:level4 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l9:level5 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l9:level6 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l9:level7 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l9:level8 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l9:level9 lfo22 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

@list l9:level1 lfo23 {
  mso-level-start-at: 14;
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 21.2500pt;
  text-indent: -21.2500pt;
  font-family: 宋体;
}

@list l9:level2 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l9:level3 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  margin-left: 0.0000pt;
  text-indent: 0.0000pt;
  font-family: 宋体;
}

@list l9:level4 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 99.2000pt;
  text-indent: -35.4000pt;
  font-family: 宋体;
}

@list l9:level5 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 127.5500pt;
  text-indent: -42.5000pt;
  font-family: 宋体;
}

@list l9:level6 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 163.0000pt;
  text-indent: -56.7000pt;
  font-family: 宋体;
}

@list l9:level7 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 191.3500pt;
  text-indent: -63.8000pt;
  font-family: 宋体;
}

@list l9:level8 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 219.7000pt;
  text-indent: -70.9000pt;
  font-family: 宋体;
}

@list l9:level9 lfo23 {
  mso-level-number-format: decimal;
  mso-level-suffix: tab;
  mso-level-text: "%1.%2.%3.%4.%5.%6.%7.%8.%9";
  mso-level-tab-stop: none;
  mso-level-number-position: left;
  margin-left: 255.1000pt;
  text-indent: -85.0000pt;
  font-family: 宋体;
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 2;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 18.0000pt;
}

h3 {
  mso-style-name: "标题 3";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 3;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 13.5000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.16 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  margin: 0pt;
  margin-bottom: .0001pt;
  border-bottom: 1.0000pt solid windowtext;
  mso-border-bottom-alt: 0.7500pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: center;
  font-family: Calibri;
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  margin: 0pt;
  margin-bottom: .0001pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
}

p.20 {
  mso-style-name: 修订;
  mso-style-noshow: yes;
  margin: 0pt;
  margin-bottom: .0001pt;
  font-family: Calibri;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.3000pt 841.9000pt;
  layout-grid: 15.6000pt;
  mso-header-margin: 42.5500pt;
  mso-footer-margin: 49.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>