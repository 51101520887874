/**
 * @Description: 分销商系统api
 * @author jing
 * @date 2021/11/22 14:44
 * @params:
 */
import commonApi from "@/api/commonApi";

const distributorApi = {
  ...commonApi,
  post_carrierShippingMethod_queryCarrierShippingName_api: '/erpCommon/yms-core-service/ymsCarrierShippingMethod/api/queryCarrierShippingName', // 物流-批量查询邮寄方式 api接口
  distributor_post_ymsProductCategory_shopping_queryForExistsProduct: '/erpCommon/yms-core-service/ymsProductCategory/shopping/queryForExistsProduct', // 返回当前分类下都是有sku的分类接口
  get_distributorErpConfig: '/erpCommon/loadConfig', // 用户信息
  get_ymsDistributorUserInfo_getAllUser: '/ymsDistributorUserInfo/getAllUser', // 获取用户信息
  post_ymsDistributorProductInfo_query: '/ymsDistributorProductInfo/query', // 商品管理列表
  post_ymsDistributorProductInfo_queryProductListingAccount: '/ymsDistributorProductInfo/queryProductListingAccount', // 查询商品listing刊登情况
  get_ymsMerchantSubscription_api_queryDifferentStateCount: '/erpCommon/yms-core-service/ymsMerchantSubscription/api/queryDifferentStateCount/', // 获取收藏的商品中的总数
  post_ymsProductInfo_queryByYmsProductGoodsIds: '/erpCommon/yms-core-service/ymsProductInfo/api/queryByYmsProductGoodsIds', // 商品id查询产品信息
  post_ymsPlatformOrder_replacePackageGoods: '/ymsPlatformOrder/replacePackageGoods', // 更换订单商品
  post_ymsDistributorBill_query: '/ymsDistributorBill/query', // 我的账单
  distributor_get_ymsPackageDetail_api_queryBillId: '/erpCommon/yms-core-service/ymsPackageDetail/api/queryBillId', // 分销商账单明细列表
  get_ymsDistributorBill: '/ymsDistributorBill/', // 基本信息
  get_ymsDistributorMerchant_getYmsDistributorMerchantDetail: '/ymsDistributorMerchant/getYmsDistributorMerchantDetail', // 获取分销商的信息
  get_ymsDistributorMerchant: '/ymsDistributorMerchant', // 账号管理---基本信息
  put_ymsDistributorMerchant_update_info: '/ymsDistributorMerchant/update', // 更新基本信息
  post_ymsDistributorProductInfo_download: '/ymsDistributorProductInfo/download', // 商城模块-下载数据
  post_ymsDistributorMerchantSubscription_subscription: '/ymsDistributorMerchantSubscription/subscription', // 商城模块-收藏
  post_ymsDistributorMerchantSubscription_cancelSubscription: '/ymsDistributorMerchantSubscription/cancelSubscription', // 取消收藏
  distributor_get_captcha: '/erpCommon/yms-core-service/captcha', // 图片验证码链接
  distributor_post_ymsUserSetting_sendMobileVerificationCode: '/erpCommon/yms-core-service/ymsUserInfo/sendMobileVerificationCode', // 发送手机验证码
  distributor_post_ymsUserSetting_sendEmailVerificationCode: '/erpCommon/yms-core-service/ymsUserInfo/sendEmailVerificationCode', // 发送邮箱验证码
  distributor_post_ymsUserSetting_validVerificationCode: '/erpCommon/yms-core-service/ymsUserInfo/validVerificationCode', // 下一步
  get_captcha_validImageCode: '/captcha/validImageCode', // 仅校验图片验证码
  post_ymsDistributorMerchant_updateEmail: '/ymsDistributorMerchant/updateEmail', // 更新邮箱
  post_ymsDistributorMerchant_updateMobile: '/ymsDistributorMerchant/updateMobile', // 更新手机
  put_ymsDistributorMerchant_updatePassword: '/ymsDistributorMerchant/updatePassword', // 更新密码
  post_ymsDistributorStationNotification_query: '/ymsDistributorStationNotification/query', // 获取商品通知
  put_ymsDistributorStationNotification_markRead: '/ymsDistributorStationNotification/markRead', // 标记已读
  put_ymsStationNotification_api_markRead: '/erpCommon/yms-core-service/ymsStationNotification/api/markRead', // 标记已读
  put_ymsDistributorStationNotification_markAllAndNewRead: '/ymsDistributorStationNotification/markAllAndNewRead', // 全部标记已读
  get_ymsDistributorStationNotification_markPopipRead: '/ymsDistributorStationNotification/markPopipRead/', // 弹窗消息通知（全部标记已读）
  get_ymsDistributorStationNotification: '/ymsDistributorStationNotification/', // 获取商品通知详情
  post_ymsSupplierMerchant_queryStore: '/ymsSupplierMerchant/queryStore', // 已关注店铺列表
  distributor_post_ymsSupplierMerchant_deleteAssociatedStore: '/ymsSupplierMerchant/deleteAssociatedStore', // 取消关注
  post_ymsDistributorStationNotification_queryNew: '/ymsDistributorStationNotification/queryNew', // 获取店铺上新通知
  get_ymsSupplierMerchant_distributor: '/erpCommon/yms-core-service/ymsSupplierMerchant/', // 获取供应商的基本数据
  post_ymsSupplierMerchant_api_queryByIds__distributor: '/erpCommon/yms-core-service/ymsSupplierMerchant/api/queryByIds', // 批量获取供应商基本信息
  put_ymsProductNotice_markReadForNew: '/ymsProductNotice/markReadForNew', // 标记上新通知已读
  post_ymsDistributorPackageInfo_query: '/ymsDistributorPackageInfo/query', // YMS订单
  get_ymsDistributorPackageInfo: '/ymsDistributorPackageInfo/', // YMS订单详情
  put_ymsDistributorPackageInfo_updateOrderBuyerInfo: '/ymsDistributorPackageInfo/updateOrderBuyerInfo', // 修改收货人信息
  put_ymsDistributorPackageInfo_validateLogis: '/ymsDistributorPackageInfo/validateLogis', // 校验变更地址是否可达
  put_ymsDistributorPackageInfo_updateOrderCarrierInfo: '/ymsDistributorPackageInfo/updateOrderCarrierInfo', // 修改物流商信息
  put_ymsPlatformOrder_updateOrderCarrierInfo: '/ymsPlatformOrder/updateOrderCarrierInfo', // 匹配物流
  get_ymsDistributorPackageInfo_searchLogisticsTracking: '/ymsDistributorPackageInfo/searchLogisticsTracking/', // 获取轨迹信息
  post_ymsDistributorProductInfo_product: '/ymsDistributorProductInfo/product', // 生成售卖资料
  post_ymsDistributePackageCost_query: '/ymsDistributePackageCost/query', // 获取分销商费用明细的数据
  post_ymsPlatformOrder_query: '/ymsPlatformOrder/query', // 平台订单列表
  post_ymsPlatformOrder_excelYmsPlatformOrderExport: '/ymsPlatformOrder/excelYmsPlatformOrderExport', // 导出平台订单数据
  put_ymsPlatformOrder_waitingForConfirmation: '/ymsPlatformOrder/waitingForConfirmation', // 等待确认
  put_ymsPlatformOrder_handMarkedShipmentSuccess: '/ymsPlatformOrder/handMarkedShipmentSuccess/', // 手动标记发货
  get_ymsPlatformOrder_queryTotal: '/ymsPlatformOrder/queryTotal', // 获取商品数
  get_ymsPlatformOrder_matchingGoods: '/ymsPlatformOrder/matchingGoods/', // 处理订单
  post_ymsPlatformOrder_isYmsOrderUpdate: '/ymsPlatformOrder/isYmsOrderUpdate', // 标记非YMS订单
  put_ymsPlatformOrder_invalid: '/ymsPlatformOrder/invalid', // 取消/作废订单
  get_ymsPlatformOrder: '/ymsPlatformOrder/', // 获取平台订单详情数据
  post_ymsPlatformOrder_matchProductGoods: '/ymsPlatformOrder/matchProductGoods', // 手工匹配商品
  put_ymsPlatformOrder_executionRules: '/ymsPlatformOrder/executionRules', // 执行规则
  put_ymsPlatformOrder_markedShipment: '/ymsPlatformOrder/markedShipment', // 标发货
  post_ymsPlatformOrder_syncUploadLogistics: '/ymsPlatformOrder/syncUploadLogistics', // 下发物流
  put_ymsPlatformOrder_batchPlaceOrder: '/ymsPlatformOrder/batchPlaceOrder', // 批量下单
  post_ymsPlatformOrder_packageGoods: '/ymsPlatformOrder/packageGoods', // YMS订单商品详情
  post_ymsDistributorProductInfo_queryYmsProductGoods: '/ymsDistributorProductInfo/queryYmsProductGoods', // 获取添加商品列表数据
  put_ymsDistributorPackageInfo_updateOrderGoods: '/ymsDistributorPackageInfo/updateOrderGoods', // 修改商品
  get_ymsDistributorPackageInfo_tradeSellerOrderDecrypt: '/ymsDistributorPackageInfo/tradeSellerOrderDecrypt/', // 解密买家信息
  post_ymsLog_query: '/ymsLog/query', // 查看平台订单的操作历史
  post_ymsPlatformAccount_queryYmsPlatformAccountByIds: '/ymsPlatformAccount/queryYmsPlatformAccountByIds', // 根据ymsPlatformAccountId获取对应店铺的code
  post_ymsPlatformAccount_query: '/ymsPlatformAccount/query', // 店铺管理列表
  put_ymsPlatformAccount_disable: '/ymsPlatformAccount/disable', // 停用店铺
  put_ymsPlatformAccount_enable: '/ymsPlatformAccount/enable', // 启用店铺
  post_ymsPlatformAccount_confirmRenewal: '/ymsPlatformAccount/confirmRenewal', // 店铺续费
  get_ymsPlatformAccount_callback: '/ymsPlatformAccount/callback', // 授权回调
  get_ymsPlatformAccount_queryAuthAccountList: '/ymsPlatformAccount/queryAuthAccountList', // 获取指定平台下店铺的数据
  post_ymsPlatformAccount_queryYmsPlatformAccountList: '/ymsPlatformAccount/queryYmsPlatformAccountList', // 查询店铺列表
  post_ymsPlatformAccount_applyAuth: '/ymsPlatformAccount/applyAuth', // 申请授权

  post_ymsLogisService_query: '/ymsLogisService/query', // 获取物流设置列表数据
  get_ymsLogisService: '/ymsLogisService/', // 查看物流设置

  post_ymsLogisServiceAutoRule: "/ymsLogisServiceAutoRule",// 新增物流规则
  post_ymsLogisServiceAutoRule_query: "/ymsLogisServiceAutoRule/query", // 查询物流自动审核规则列表
  get_ymsLogisServiceAutoRule: "/ymsLogisServiceAutoRule/",// 获取规则详情
  get_ymsLogisServiceAutoRule_enable: "/ymsLogisServiceAutoRule/enable/", // 启动物流自动审核规则
  get_ymsLogisServiceAutoRule_disable: "/ymsLogisServiceAutoRule/disable/", // 停用物流自动审核规则
  put_ymsLogisServiceAutoRule: "/ymsLogisServiceAutoRule", // 更新物流自动审核规则
  put_ymsLogisServiceAutoRule_updatePriority: "/ymsLogisServiceAutoRule/updatePriority", // 更新自动规则优先级
  delete_ymsLogisServiceAutoRule: "/ymsLogisServiceAutoRule", // 删除订单物流自动规则

  get_ymslogisServiceProposalRule_query: "/ymsLogisServiceProposalRule/query", // 查询智能物流规则
  put_ymslogisServiceProposalRule_batchUpdate: "/ymsLogisServiceProposalRule/batchUpdate", // 更新智能物流规则

  get_ymsLogisServiceScopeRule_query: "/ymsLogisServiceScopeRule/query", // 查询物流渠道适用范围列表
  post_ymsLogisServiceScopeRule: "/ymsLogisServiceScopeRule", // 更新物流渠道适用范围
  post_authAccountToken_applyAuth: "/ymsPlatformAccount/queryAuthAccountList",
  post_ymsPlatformAccount: "/ymsPlatformAccount/",
  get_ymsSalesPlatform_query: "/ymsSalesPlatform/query",
  post_ymsLogisService_api_queryByIds: '/erpCommon/yms-core-service/ymsLogisService/api/queryByIds', // Id查询列表
  post_ymsLogisService_api_queryAll: '/erpCommon/yms-core-service/ymsLogisService/api/queryAll', // YMS物流查询已启用
  post_ymsLogisService_api_queryList: '/erpCommon/yms-core-service/ymsLogisService/api/queryList', // YMS物流查询所有
  post_ymsLog_api_queryByBusinessId: '/erpCommon/yms-core-service/ymsLog/api/queryByBusinessId', // 查询运营的分销商备货单日志

  get_salesPlatformSite_query: '/erpCommon/common-service/salesPlatformSite/query',
  get_carrierShippingMethodPrice: '/carrierShippingMethodPriceModel/getByShippingMethodId', // 获取引用其他渠道的计费模板信息的数据
  get_carrier_queryForEnableCarriers: '/carrier/queryForEnableCarriers', // 查询可用物流商(api对接)
  get_queryShippingMethod: '/merchantShippingMethod/queryShippingMethod', // 商户平台-服务
  add_merchantShippingMethod: '/merchantShippingMethod', // 新增商户平台邮寄方式
  delete_merchantShippingMethod: '/merchantShippingMethod/', // 删除商户平台邮寄方式
  post_ymsDistributorMerchantRechargeRecord_query: '/ymsDistributorMerchantRechargeRecord/query', // 充值记录列表
  post_ymsDistributorMerchantRechargeRecord_exportRechargeRecord: '/ymsDistributorMerchantRechargeRecord/exportRechargeRecord', // 导出充值记录数据
  post_ymsProductCategory_api_queryAll: '/erpCommon/yms-core-service/ymsProductCategory/api/queryAll', // 获取YMS分类的数据
  post_ymsDistributorMerchantRechargeRecord_alipay: '/ymsDistributorMerchantRechargeRecord/alipay',
  get_ymsMerchantProperty_api_queryByYmsDistributorMerchantId: '/erpCommon/yms-core-service/ymsMerchantProperty/api/queryByYmsDistributorMerchantId/', // 查询分销商余额
  get_distributorWebsocket: '/notify/default/', // websocket
  get_ymsDistributorStationNotification_lastUnRead: '/ymsDistributorStationNotification/lastUnRead', // 获取消息提醒的数据
  get_ymsDistributorStationNotification_queryStationNotificationList: '/ymsDistributorStationNotification/queryStationNotificationList', // 获取消息提醒的数据

  post_ymsDistributorCommon_loadConfig: '/ymsDistributorCommon/loadConfig', // 临时方案 获取isMultiSpecificationAttributeMerchant
  get_ymsDistributorPackageInfo_queryStatusStatistics: '/ymsDistributorPackageInfo/queryStatusStatistics', // 查询订单统计状态

  get_ymsDeliveryAddress_query: '/ymsDeliveryAddress/query', // 获取收货地址列表
  post_ymsDeliveryAddress: '/ymsDeliveryAddress', // 新增收货地址
  put_ymsDeliveryAddress: '/ymsDeliveryAddress', // 更新收货地址
  delete_ymsDeliveryAddress: '/ymsDeliveryAddress/', // 删除收货地址
  put_ymsDeliveryAddress_updateStatus: '/ymsDeliveryAddress/updateStatus/', // 设置默认的收货地址
  post_ymsPlatformOrder_createOrder: '/ymsPlatformOrder/createOrder', // 新增手工订单

  post_ymsDistributePackageCost_batchExport: '/ymsDistributePackageCost/batchExport', // 费用明细导出
  post_erpCommon_queryTask: '/erpCommon/queryTask', // 导入导出查看列表
  get_ymsDistributorCommon_validMerchant: '/ymsDistributorCommon/validMerchant', // 检验商户信息

  get_captcha: '/ymsDistributorMerchant/captcha', // 图片验证码链接
  put_ymsDistributorMerchant_updateMerchant: '/ymsDistributorMerchant/updateMerchant', // 修改分销商账号
  post_ymsUserSetting_sendMobileVerificationCode: '/erpCommon/yms-core-service/ymsUserInfo/sendMobileVerificationCode', // 发送手机验证码
  post_ymsPlatform_api_query: '/erpCommon/yms-core-service/ymsPlatform/api/query', //获取平台
  get_ymsPlatformOrder_queryPlatformName: '/ymsPlatformOrder/queryPlatformName', // 获取子账号授权店铺过滤的平台
  get_ymsPlatform_api_queryProductPlatform: '/erpCommon/yms-core-service/ymsPlatform/api/queryProductPlatform', //获取已经映射商品的平台数据
  get_ymsProductCategory_checkIsApparelCategory: '/erpCommon/yms-core-service/ymsProductCategory/api/checkIsApparelCategory', // 判断平台是否开通服装大类
  get_ymsProductInfo_queryPopulerCategoryNameBySkuNumber: '/ymsDistributorProductInfo/shopping/queryPopulerCategoryNameBySkuNumber', // 获取Sku数量最多的六个分类信息
  get_ymsProductInfo_shopping_queryForShowCase: '/ymsDistributorProductInfo/shopping/queryForShowCase',
  get_ymsProductInfo_shopping: '/ymsDistributorProductInfo/shopping/', // yms根据id查询商品详情
  post_ymsProductInfo_shopping_query: '/ymsDistributorProductInfo/shopping/query', // 商城--商品列表
  post_ymsProductInfo_api_priceTrial: '/erpCommon/yms-core-service/ymsPackageInfo/api/priceTrial', // 计算运费

  post_erpCommonSettingParam_save: '/erpCommonSettingParam/save', // 保存系统的属性设置
  post_ymsDistributorPackageInfo_excelYmsPackageInfosExport: '/ymsDistributorPackageInfo/excelYmsPackageInfosExport', // 导出YMS订单

  get_erpCommon_ymsActivityPlan_shopping: '/erpCommon/yms-core-service/ymsActivityPlan/shopping', // 查询商城中正在进行中的活动
  get_erpCommon_ymsAdvertisement_shopping: '/erpCommon/yms-core-service/ymsAdvertisement/shopping', // 查询商城已启用的广告

  post_role_query: '/role/query', // 获取所有角色列表
  get_role: '/role/', // 获取角色的详情数据
  post_ymsDistributorUserInfo_id: '/ymsDistributorUserInfo/', // 查看员工信息
  post_ymsDistributorUserInfo: '/ymsDistributorUserInfo', // 新增员工
  put_ymsDistributorUserInfo: '/ymsDistributorUserInfo', // 修改员工
  put_ymsDistributorUserInfo_enable: '/ymsDistributorUserInfo/enable', // 启用员工
  put_ymsDistributorUserInfo_disable: '/ymsDistributorUserInfo/disable', // 停用员工
  delete_role: '/role/', // 删除角色
  post_ymsDistributorUserInfo_query: '/ymsDistributorUserInfo/query', // 分页查询员工列表
  post_role: '/role', // 新增角色
  put_role: '/role', // 修改角色
  get_ymsDistributorUserInfo_queryMenu: '/ymsDistributorUserInfo/queryMenu', // 查询用户菜单
  post_ymsDistributorUserInfo_queryOperByMenu: '/ymsDistributorUserInfo/queryOperByMenu/', // 获取单页权限

  post_ymsPlatformAccount_queryAll: '/ymsPlatformAccount/queryAll', // 获取平台店铺的所有数据
  get_ymsCarrier_queryDistributorCarrier: '/ymsCarrier/queryDistributorCarrier', // 获取物流商的数据
  get_ymsCarrierShippingMethod_queryForEnableShippingMethods: '/ymsCarrierShippingMethod/queryForEnableShippingMethods', // 获取对应物流商下的物流渠道
  put_ymsCarrier_disable: '/ymsCarrier/disable/', // 停用物流商
  put_ymsCarrier_enable: '/ymsCarrier/enable', // 启用物流商
  get_ymsCarrierShippingMethod: '/ymsCarrierShippingMethod/', // 获取物流渠道设置详情
  get_ymsCarrierShippingMethod_disable: '/ymsCarrierShippingMethod/disable/', // 停用自有物流---物流渠道
  put_ymsCarrierShippingMethod_enable: '/ymsCarrierShippingMethod/enable', // 启用自有物流---物流渠道
  put_ymsCarrierShippingMethod_update: '/ymsCarrierShippingMethod/update', // 编辑自有物流--物流渠道信息

  post_ymsInventorySynRule_insert: '/ymsInventorySynRule/insert', // 新增库存同步
  get_ymsInventorySynRule_queryAll: '/ymsInventorySynRule/queryAll', // 库存同步列表
  put_ymsInventorySynRule_update: '/ymsInventorySynRule/update', // 更新库存同步
  delete_ymsInventorySynRule: '/ymsInventorySynRule/', // ymsInventorySynRuleId 更新库存同步
  get_ymsInventorySynRule: '/ymsInventorySynRule/', // ymsInventorySynRuleId 库存同步详情
  get_wmsWarehouse_queryYmsWarehouse: '/wmsWarehouse/queryYmsWarehouse', // 查询YMS中心仓仓库

  get_ymsCarrierAccount_queryByCarrierId: '/ymsCarrierAccount/queryByCarrierId/', // 获取自有物流--物流账户信息
  put_ymsCarrierAccount_enable: '/ymsCarrierAccount/enable/', // 物流账户启用
  put_ymsCarrierAccount_disable: '/ymsCarrierAccount/disable/', // 物流账户停用
  post_ymsCarrierAccount_applyCarrierAuth: '/ymsCarrierAccount/applyCarrierAuth', // 自有物流--物流账户--申请授权
  put_ymsCarrierAccount_saveAccountRelated: '/ymsCarrierAccount/saveAccountRelated', // 关联店铺
  get_ymsCarrier_queryDistributorEnableCarrier: '/ymsCarrier/queryDistributorEnableCarrier', // 查询自有物流已启用物流数据
  get_carrierAccount_getCarrierAccount: '/carrierAccount/getCarrierAccount/', // 获取新账户模板{carrierId}
  post_carrierAccount_save: '/carrierAccount/save', // 保存账号信息

  get_ymsQuestionnaire_api_showQuestionnaire: '/erpCommon/yms-core-service/ymsQuestionnaire/api/showQuestionnaire/', // 获取调研问卷的数据
  post_ymsQuestionnaire_api_submitQuestionnaire: '/erpCommon/yms-core-service/ymsQuestionnaire/api/submitQuestionnaire', // 调研问卷的数据保存
  get_ymsQuestionnaire_api_visitedQuestionnaire: '/erpCommon/yms-core-service/ymsQuestionnaire/api/visitedQuestionnaire/', // 记录问卷访问量
  get_ymsDistributorAscriptionWarehouse_api_query: '/erpCommon/yms-core-service/ymsDistributorAscriptionWarehouse/api/query/', // 获取对应商户的所属仓库数据

  get_ymsCurrencyConfig_query: '/erpCommon/yms-core-service/ymsCurrencyConfig/api/query', // 查询系统币种配置数据
  post_erpCommonSettingParam_queryMerchantByParamKeys: '/erpCommonSettingParam/queryMerchantByParamKeys',
  post_erpCommonSettingParam_saveSetting: '/erpCommonSettingParam/saveSetting',
  post_ymsDistributorMerchant_saveCurrency: '/ymsDistributorMerchant/saveCurrency', // 分销商保存币种

  post_ymsThirdPlatformProductLabel_queryThirdPlatformProductGoodsDetail: '/ymsThirdPlatformProductLabel/queryThirdPlatformProductGoodsDetail', // 获取temu平台下的商品信息
  post_ymsThirdPlatformProductLabel_save: '/ymsThirdPlatformProductLabel/save', // 上传样品、商品条码标签
  post_ymsThirdPlatformProductLabel_queryThirdPlatformUploadSkuLabel: '/ymsThirdPlatformProductLabel/queryThirdPlatformUploadSkuLabel', // 查询未上传标签的sku
  post_ymsPlatformOrder_createPlatformOrder: '/ymsPlatformOrder/createPlatformOrder', // 新增平台手工单(Temu订单)
  put_ymsPlatformOrder_implementShipment: '/ymsPlatformOrder/implementShipment', // 执行发货
  post_ymsPlatformOrder_queryDetailByIds: '/ymsPlatformOrder/queryDetailByIds', // 根据平台id获取小包的基础数据
  post_ymsThirdPlatformProductLabel_api_checkNotLabel: '/erpCommon/yms-core-service/ymsThirdPlatformProductLabel/api/checkNotLabel', // 校验无标签商品

  post_ymsNoticeInfo_queryNewNoticeInfo: '/ymsNoticeInfo/queryNewNoticeInfo', // 查询当前系统最新公告
  post_ymsNoticeInfo_disable: '/ymsNoticeInfo/disable', // 标记系统公告已读

  post_productSalesLog_querySalesLog: '/productSalesLog/querySalesLog', // 查询售卖资料记录
  get_productSalesLog_queryCount: '/productSalesLog/queryCount', // 获取售卖资料记录的总数
  post_productSalesLog_stopProduct: '/productSalesLog/stopProduct', // 停止生成售卖资料
  post_ymsDistributorProductInfo_querySaleAccountInfoListByPlatform: '/ymsDistributorProductInfo/querySaleAccountInfoListByPlatform', // 查询可以刊登的店铺信息
  post_ymsDistributorProductInfo_queryTemplateByPlatform: '/ymsDistributorProductInfo/queryTemplateByPlatform', // 查询可刊登的模版
  post_ymsDistributorProductInfo_draft: '/ymsDistributorProductInfo/draft', // 一键刊登草稿

  get_ymsLangDictionaryDetail_queryLang: '/erpCommon/yms-core-service/ymsLangDictionaryDetail/queryLang', // 获取系统的语种配置

  post_ymsPlatformOrder_saveMarkedShipmentSetting: '/ymsPlatformOrder/saveMarkedShipmentSetting', // 标发货设置

  get_common_countrys_query: '/erpCommon/yms-core-service/common/country/query', // 获取所有国家的基础数据
  get_common_state_queryByCountryId: '/erpCommon/yms-core-service/common/state/queryByCountryId', // 根据countryId（国家id）获取对应省级区域的基础数据
  get_common_city_queryByStateCode: '/erpCommon/yms-core-service/common/city/queryByStateCode', // 根据code获取对应市级区域的基础数据
  get_common_district_queryByCityCode: '/erpCommon/yms-core-service/common/district/queryByCityCode', // 根据code获取对应区级区域的基础数据
  get_common_country_queryZoneCountry: '/erpCommon/yms-core-service/common/country/queryZoneCountry', // 获取地区国家的基础数据
  get_common_country_getDetailByTwoCode: '/erpCommon/yms-core-service/common/country/getDetailByTwoCode/', // 根据国家二次码获取省市区地址的基础数据

  post_ymsPlatform_shopping_query: '/ymsPlatform/shopping/query', // 商城获取平台数据
  get_ymsDistributorCommon_getPendingCount: '/ymsDistributorCommon/getPendingCount', // 获取分销商系统待处理业务的数量
  post_ymsDistributorMerchant_confirmAccount: '/ymsDistributorMerchant/confirmAccount', // 确认开通分销商账户
  put_ymsDistributorMerchant_settleIn: '/ymsDistributorMerchant/settleIn', // 通途商户确认开通分销商账号
  get_ymsAddressTemplate_api_chooseList: '/erpCommon/yms-core-service/ymsAddressTemplate/api/chooseList', // 获取已启用的地址模板数据

  post_ymsProductStatusSynRule_insert: '/ymsProductStatusSynRule/insert', // 新增商品状态同步规则
  put_ymsProductStatusSynRule_update: '/ymsProductStatusSynRule/update', // 修改商品状态同步规则
  delete_ymsProductStatusSynRule: '/ymsProductStatusSynRule/', // 删除商品状态同步规则
  get_ymsProductStatusSynRule_queryAll: '/ymsProductStatusSynRule/queryAll', // 查询商品状态同步规则
  get_ymsProductStatusSynRule: '/ymsProductStatusSynRule/', // 根据id查询商品状态同步规则

  post_ymsProductPriceSynRule_insert: '/ymsProductPriceSynRule/insert', // 新增价格同步规则
  put_ymsProductPriceSynRule_update: '/ymsProductPriceSynRule/update', // 修改价格同步规则
  delete_ymsProductPriceSynRule: '/ymsProductPriceSynRule/', // 删除价格同步规则
  get_ymsProductPriceSynRule_queryAll: '/ymsProductPriceSynRule/queryAll', // 查询价格同步规则
  get_ymsProductPriceSynRule: '/ymsProductPriceSynRule/', // 根据id查询价格同步规则

  post_ymsPackageInfoSynRule_insert: '/ymsPackageInfoSynRule/insert', // 新增YMS订单同步规则
  put_ymsPackageInfoSynRule_update: '/ymsPackageInfoSynRule/update', // 修改YMS订单同步规则
  delete_ymsPackageInfoSynRule: '/ymsPackageInfoSynRule/', // 删除YMS订单同步规则
  get_ymsPackageInfoSynRule_queryAll: '/ymsPackageInfoSynRule/queryAll', // 查询YMS订单同步规则
  get_ymsPackageInfoSynRule: '/ymsPackageInfoSynRule/', // 根据id查询YMS订单同步规则

  post_ymsPlatformOrder_orderConfirmation: '/ymsPlatformOrder/orderConfirmation', // 批量接单
  get_getShelfToolModules: '/getShelfToolModules', // 获取上架工具是的相关菜单数据

  post_ymsIoss_insert: '/ymsIoss/insert', // 新增IOSS数据
  post_ymsIoss_query: '/ymsIoss/query', // 获取IOSS管理列表数据
  put_ymsIoss_disable: '/ymsIoss/disable', // 停用IOSS
  put_ymsIoss_enable: '/ymsIoss/enable/', // 启用IOSS
  get_ymsIoss_queryEnable: '/ymsIoss/queryEnable', // 获取启用的IOSS
  ymsIoss_update: '/ymsIoss/update', // 编辑IOSS

  get_ymsIossRule_queryRateSetting: '/ymsIossRule/queryRateSetting', // 查看税率
  post_ymsIossRule_query: '/ymsIossRule/query', // 获取IOSS规则列表数据
  post_ymsIossRule_insert: '/ymsIossRule/insert', // 新增IOSS规则
  put_ymsIossRule: '/ymsIossRule', // 修改IOSS规则
  put_ymsIossRule_disable: '/ymsIossRule/disable/', // 停用IOSS规则
  put_ymsIossRule_enable: '/ymsIossRule/enable/', // 启用IOSS规则
  put_ymsIossRule_updatePriority: '/ymsIossRule/updatePriority', // 更新IOSS规则优先级
  post_ymsIoss_queryIossByIds: '/ymsIoss/queryIossByIds', // 根据ymsIossId批量获取IOSS的数据

  get_ymsDistributorPackageInfo_queryTotal: '/ymsDistributorPackageInfo/queryTotal/', // 获取YMS订单待处理业务的数量
  post_ymsPlatformOrder_getIOSSLogisticCost: '/ymsPlatformOrder/getIOSSLogisticCost', // 获取申报的物流税费
  post_ymsPlatformOrder_confirmIoss: '/ymsPlatformOrder/confirmIoss', // 录入IOSS下单
  post_ymsPlatformOrder_getIossCostToOrder: '/ymsPlatformOrder/getIossCostToOrder', // 新增手工单获取清关费用
  get_expressageHundred_query: '/erpCommon/common-service/expressageHundred/query', // 获取运输方式的数据

  post_ymsDistributorStockUpInfo_createStockOrder: '/ymsDistributorStockUpInfo/createStockOrder', // 新增分销商备货单
  post_ymsDistributorStockUpInfo_query: '/ymsDistributorStockUpInfo/query', // 分销商备货单列表
  post_ymsDistributorStockUpInfo_exportYmsDistributorStockUpInfoList: '/ymsDistributorStockUpInfo/exportYmsDistributorStockUpInfoList', // 导出备货单
  get_ymsDistributorStockUpInfo_queryTabTotal: '/ymsDistributorStockUpInfo/queryTabTotal', // 获取备货单待处理数量
  get_ymsDistributorStockUpInfo: '/ymsDistributorStockUpInfo/', // 备货单详情
  put_ymsDistributorStockUpInfo_confirmPayment: '/ymsDistributorStockUpInfo/confirmPayment/', // 确认付款
  post_ymsDistributorStockUpInfo_confirmCancel: '/ymsDistributorStockUpInfo/confirmCancel', // 确认取消

  post_ymsDistributorInventory_setDistributorInventoryPriority: '/ymsDistributorInventory/setDistributorInventoryPriority', // 库存优先级配置
  post_ymsDistributorInventory_batchExport: '/ymsDistributorInventory/batchExport', // 导出库存
  post_ymsDistributorInventory_details: '/ymsDistributorInventory/details', // 库存明细
  post_ymsDistributorInventory_query: '/ymsDistributorInventory/query', // 我的库存列表
  post_ymsDistributorInventory_queryByYmsProductIds: '/ymsDistributorInventory/queryByYmsProductIds', // 获取对应spu下的sku数据

  post_ymsDistributorPackageInfo_replacePackageGoods: '/ymsDistributorPackageInfo/replacePackageGoods', // 更换YMS订单商品
  post_ymsDistributorPackageInfo_cancelPackage: '/ymsDistributorPackageInfo/cancelPackage', // 取消YMS订单

  get_ymsDistributorMerchant_api_queryYmsCoreMerchant: '/erpCommon/yms-core-service/ymsDistributorMerchant/api/queryYmsCoreMerchant', // 获取运营商信息

  put_ymsPlatformOrder_paymentSuccess: '/ymsPlatformOrder/paymentSuccess/', // 手动标记--更新付款状态
  put_ymsPlatformOrder_markUpdateOrderStatus: '/ymsPlatformOrder/markUpdateOrderStatus/', // 去付款
  put_ymsPlatformOrder_confirmModiftAddress: '/ymsPlatformOrder/confirmModiftAddress/', // 修改物流地址
  put_ymsPlatformOrder_markDealed: '/ymsPlatformOrder/markDealed/', // 标记已处理
  put_ymsPlatformOrder_handModiftAddress: '/ymsPlatformOrder/handModiftAddress/', // 修改物流地址--手动标记已处理
  post_ymsProductCategory_api_queryDetailByCategoryIds: '/erpCommon/yms-core-service/ymsProductCategory/api/queryDetailByCategoryIds', // 根据分类id批量查询分类信息
  get_ymsSite_api_query: '/erpCommon/yms-core-service/ymsSite/api/query', // 获取所有站点数据
  get_ymsProductCategory_api_querySubCategorysById: '/erpCommon/yms-core-service/ymsProductCategory/api/querySubCategorysById/', // 获取当前分类下的子类目数据
  post_ymsProductCategory_api_queryCategorysByName: '/erpCommon/yms-core-service/ymsProductCategory/api/queryCategorysByName', // 根据关键字查询类目
};

for (let key in distributorApi) {
  distributorApi[key] = '/yms-distributor-service' + distributorApi[key];
}

export default distributorApi;
