// @all-no-translate
<template>
  <div class="Section0" style="layout-grid:15.6000pt;">
    <h2 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(29,32,38);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">Y</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(29,32,38);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);">MS</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(29,32,38);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台隐私政策</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
color:rgb(29,32,38);mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span></b></h2>
    <h1 align=center style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-pagination:widow-orphan;
text-align:center;line-height:18.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;"><font face="微软雅黑">更新日期：</font><font face="微软雅黑">2021年1</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;">1</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;"><font face="微软雅黑">月</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;">3</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;"><font face="微软雅黑">日</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.0000pt;mso-font-kerning:22.0000pt;"></span></h1>
    <h1><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:22.0000pt;"><font face="微软雅黑">引言</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:normal;font-size:10.5000pt;mso-font-kerning:22.0000pt;"></span></h1>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，您的信任对我们非常重要，我们将根据法律法规要求并参照行业最佳实践为您的个人信息安全提供充分保障。鉴于此，</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台服务提供者（或简称</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">“我们”</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">）制定本《隐私政策》（下称</font><font face="微软雅黑">“本政策”/“本隐私政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">粗体</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">/粗体下划线</span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">本隐私政策将帮助您了解以下内容：</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">一、适用范围</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">二、信息收集及使用</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">三、对外提供信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">四、您的权利</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">五、信息的存储</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">六、政策的更新</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">七、未成年人保护</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">八、联系我们</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">附录：相关定义</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">一、适用范围</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">本政策适用于</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">YMS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台服务提供者以网站、客户端、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们就其向您提供的产品或服务单独设有隐私政策的，则相应产品或服务适用相应隐私政策。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">除本政策说明的相关信息收集使用活动外，本政策不适用于</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">YMS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台供应商及其他第三方向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。例如，当您加入供应商时，则此类供应商对会员信息的使用适用其专门向您说明的规则。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">二、信息收集及使用</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在您使用我们的产品</font><font face="微软雅黑">/服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如您拒绝提供相应信息，您将无法正常使用我们的产品及</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">/或服务；</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，不会影响您使用我们的基本功能。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们在此特别提醒您：我们致力于打造多样的产品和服务以满足您的需求。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">/服务范围存在差异，故基本/附加功能及对应收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准。</span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们会为您提供的各项具体功能场景包括：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（一）帮助您成为我们的会员及账户管理</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、基础会员服务</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们基于</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">YMS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台账户为您提供服务。为了创建</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">YMS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台账户您需要至少向我们提供您的</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">手机号码、拟使用的会员名和密码</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">。如果您拒绝提供上述信息，您将无法注册</font><font face="微软雅黑">YMS平台账户，仅可以使用浏览、搜索服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们会根据您的</font><font face="微软雅黑">YMS平台账户使用情况及平台设置的账户等级计算规则确定您当前的等级，并为您提供相应账户等级所对应的基本权益。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、附加会员服务</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">特别会员权益：如果您提供以下额外资料补全信息，将有助于我们给您提供更个性化的产品或服务：</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您的真实姓名、固定电话、联系方式、主营行业、昵称和头像，</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们还可以为您提供特别会员服务。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">其中，您设置的昵称和头像，将公开显示。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">实人认证：您可以根据认证要求提交相应的</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">身份证件信息（身份证、护照及其他身份证件信息）、面部信息</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">以完成实人认证。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">授权登录：我们可能会根据您的单独同意从第三方处获取您的第三方账户信息，并与您的</font><font face="微软雅黑">YMS平台账户进行绑定，使您可在第三方处直接登录、使用我们的产品及/或服务。我们将在您单独同意的授权范围内使用您的相关信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">会员角色服务：如果您申请注册成为分销商、供应商或其他存在额外身份认证要求的会员角色，您需主动向我们提供身份信息及</font><font face="微软雅黑">/或企业相关信息，用于您身份及其他会员角色获取资格的核验、登记、公示及其他我们明确告知的目的。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如果您已拥有</font><font face="微软雅黑">YMS平台账户，我们可能会在YMS平台服务中显示您的上述个人信息，以及您在YMS平台上或与YMS平台账户相关联的产品和服务中执行的操作</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（您可通过</font><font face="微软雅黑">YMS平台账户在我们网站或者客户端提供的链接入口或其他产品/服务入口使用我们提供的产品或服务），</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">包括通过</font><font face="微软雅黑">YMS平台账户展示您的个人资料、优惠权益、交易订单。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们会尊重您对</font><font face="微软雅黑">YMS平台服务和YMS平台账户设置所做的选择。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（二）向您展示商品或服务信息</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为向您展示商品或服务信息，包括您的访问足迹、历史搜索等情况，我们会收集和使用您在访问或使用</font><font face="微软雅黑">YMS平台网站或客户端时的浏览、搜索记录。我们会结合依法收集的设备信息、服务日志信息，以及其他取得您授权的信息，通过算法模型预测您的偏好特征，匹配您可能感兴趣的商品、服务或其他信息，对向您展示的商品、服务或其他信息进行排序。为满足您的多元需求，我们会在排序过程中引入多样化推荐技术，拓展推荐的内容，避免同类型内容过度集中。我们也会基于您的偏好特征在YMS平台及其他第三方应用程序向您推送您可能感兴趣的商业广告及其他信息，或者向您发送商业性短信息。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">同时，我们通过以下措施努力保障您的隐私体验：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、如您要删除您的浏览和搜索记录，您可以自行选择删除；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、如您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（三）为您提供商品订阅、供应商收藏功能</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在您浏览我们网站或客户端的过程中，您可以选择对感兴趣的商品及</font><font face="微软雅黑">/或服务进行订阅、与您感兴趣的商家/品牌建立关注关系。在您使用上述功能的过程中，我们会收集包括您的收藏的记录、关注关系在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以在商品管理查看或管理您的收藏信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（四）帮助您完成下单及订单管理</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">当您在我们的产品及</font><font face="微软雅黑">/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需至少提供您的</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">收货人姓名、收货地址、收货人联系电话，</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">对于部分特殊类型的商品和服务您还需要提供该商品或服务所必需的其他信息，例如涉及</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">跨境交易时您需要提供收货人的身份证件信息以完成清关。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">同时该订单中会载明您所购买的商品及</font><font face="微软雅黑">/或服务信息、具体订单号、订单创建时间、您应支付的金额、您的备注信息。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、便于您查询订单信息、提供客服与售后服务及其他我们明确告知的目的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过</font><font face="微软雅黑">YMS平台为其他人订购商品及/或服务，您需要提供该实际收货人的前述个人信息，并确保已取得该实际收货人的同意。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为便于您了解、查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。</font> </span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可额外填写</font><font face="微软雅黑">/选择包括</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">其他联系电话、</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">收货时间在内的更多附加信息以确保商品或服务的准确送达。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过</font><font face="微软雅黑">“我的订单”查看和管理您的订单信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（五）帮助您完成支付</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为完成订单支付，我们为您提供了多种支付途径，当您使用余额支付时，您需要接受并授权身份信息开通</font><font face="微软雅黑">Y</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">MS</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台提供的第三方支付账户，对余额账户进行充值，并选择余额支付。关于平台的提供的第三方支付方式，属于独立隐私政策，会在您申请开通的时候提供给您。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象或您选择的其他金融机构处收集与支付进度相关信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（六）帮助向您完成商品或服务的交付</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为保证您购买的商品及</font><font face="微软雅黑">/或服务能够顺利、安全、准确送达、提供，我们会向为YMS平台提供物流信息系统和技术服务的披露订单相关的配送信息，并由其根据商品及/或服务提供主体的选择向相应的物流配送主体同步相关配送信息。您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为向您提供售后与争议解决服务之目的，我们需要及时获悉并确认交付进度及状态，您同意我们可自物流相关服务主体处收集与交付进度相关的信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（七）客服及争议处理</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">当您与我们联系或提出售中、售后、争议纠纷处理申请时，为了保障您的账户及系统安全，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们需要您提供必要的个人信息以核验您的会员身份。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信</font><font face="微软雅黑">/通话记录及相关内容（包括账户信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账户信息和订单信息。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;
mso-font-kerning:1.0000pt;"><font face="宋体">为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（八）为您提供安全保障</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为履行保障电子商务交易安全的法定义务，提高您使用我们、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入风险，更准确地识别违反法律法规或</font><font face="微软雅黑">YMS平台相关协议、规则的情况，我们在应用程序中嵌入第三方公司开发的应用安全SDK收集您的设备信息、服务日志信息，并可能使用或整合您的会员信息、交易信息、设备信息、服务日志信息以及第三方公司、合作伙伴取得您授权或依据法律共享的信息（其中，我们可能会在应用</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">后台运行期间收集</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">MAC地址、IMEI及其他设备标识符进行风险校验），来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（九）为您提供的其他附加服务</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、基于系统权限的附加服务</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为向您提供更便捷、更优质、个性化的产品及</font><font face="微软雅黑">/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限以收集和使用您的个人信息。</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如果您不同意开启相关权限，不会影响您使用</font><font face="微软雅黑">YMS平台的基本服务，</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">但您可能无法获得这些附加服务给您带来的用户体验。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可自行决定这些权限随时的开启或关闭。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、Cookie和同类技术的服务</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您使用我们的服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符（以下简称“Cookie”），以收集、标识和存储您访问、使用本产品时的信息。我们承诺，不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用 Cookie 主要为了保障产品与服务的安全、高效运转，可以使我们确认您账户与交易的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复您填写表单、输入搜索内容的步骤和流程。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">同时，我们可能会利用</font> <font face="微软雅黑">Cookie 向您展示您可能感兴趣的信息或功能，并优化您对广告的选择。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以进行相应的数据清除操作，或可修改对Cookie的接受程度或拒绝我们的Cookie。您可能因为这些修改，无法使用依赖于Cookie的服务或相应功能。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">三、对外提供信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（一）共享</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们不会与</font><font face="微软雅黑">YMS平台服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、履行法定义务所必需的共享：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、取得您同意的情况下共享：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">获得您的明确同意后，我们会与其他方共享您的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、为订立、履行您作为一方当事人的合同所必需的情况下的共享：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您通过</font><font face="微软雅黑">YMS平台购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易、配送及售后服务需求。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、与关联公司间共享：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为便于我们与关联公司共同向您提供部分服务，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">基于</font><font face="微软雅黑">YMS平台账户向您提供产品和服务，推荐您可能感兴趣的信息，识别会员账户异常，保护YMS平台关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的敏感个人信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（二）委托处理</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们可能委托授权合作伙伴处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">目前，我们委托的授权合作伙伴包括以下类型：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查，以及在您选择</font><font face="微软雅黑">“成为分销商”或“成为供应商”时，将您的姓名，联系方式、平台账户信息、您的注册时间和最近登录系统时间提供服务提供商以便其联系您来帮助您成为我们的会员。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（三）转让</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如果我们因合并、分立、解散、被宣告破产的原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（四）公开披露</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们仅会在以下情况下，公开披露您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、如果我们确定您出现违反法律法规或严重违反YMS平台相关协议及规则的情况，或为保护YMS平台用户或公众的人身财产安全免遭侵害，我们可能披露关于您的个人信息，包括相关违规行为以及YMS平台已对您采取的措施。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">四、您的权利</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过以下方式查阅和管理您的信息，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们会在符合法律法规要求的情形下响应您的请求：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（一）查阅、更正和补充</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您有权通过以下方式查阅、更正和补充您的信息：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、登录YMS平台，进入“我的收藏”首页，进入“账号管理-基本信息“和”账号管理-安全信息“；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、登录YMS平台网站选择联系客服。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（二）删除</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过</font><font face="微软雅黑">Y</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">MS</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">平台联系客服删除您的部分信息或申请注销账户删除您的全部信息。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在以下情形中，您可以通过与客服联系向我们提出删除个人信息的请求：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、如果我们处理个人信息的行为违反法律法规；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、如果我们处理个人信息的行为严重违反了与您的约定；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">5、如果我们停止提供产品或者服务，或者保存期限已届满。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（三）改变授权范围</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过以下方式改变或者撤回您授权我们收集和处理您的个人信息的范围：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过客服联系进行修改，</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">但请注意，对于部分类型的个人信息，例如实现</font><font face="微软雅黑">YMS平台基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">当您撤回授权后，我们将不再处理相应的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（四）注销账户</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过客服联系，申请注销您的账户。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（五）约束信息系统自动决策</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在某些业务功能中，我们可能仅依据算法在内的非人工自动决策机制做出决定。如果这些决定影响您的合法权益，您可以通过客服联系我们。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（六）响应您的上述请求</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们。我们将在</font><font face="微软雅黑">15天内做出答复。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</font></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></b></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">五、信息的存储</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（一）存储期限</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们判断个人信息的存储期限主要依据以下标准：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、保证我们为您提供服务的安全和质量；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、您是否同意更长的留存期间；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、是否存在关于保留期限的其他特别约定或法律法规规定。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（二）存储位置</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们在中华人民共和国境内运营中收集和产生的个人信息，将</font></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">存储在中国境内</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">。以下情形下，我们会在履行法律规定的义务后，向可能的境外实体提供您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、适用的法律有明确规定；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、获得您的单独同意；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、您作为一方当事人的跨境电子商务交易及其他类型合同订立和履行所必需的。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">针对以上情形，我们会按照本政策依法保护您的个人信息安全。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">（三）存储安全</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、数据保护技术措施</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受</font> <font face="微软雅黑">SSL协议加密保护，我们提供HTTPS协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、数据保护管理组织措施</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性。我们通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、个人信息安全事件的响应</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、账户安全风险的防范</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在使用</font><font face="微软雅黑">YMS平台服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。此外，您也可以通过我们的服务与第三方建立沟通、交流信息或分析内容。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过其他交易工具进行交易，以免信息被窃取甚至遭遇电信网络诈骗。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如您担心自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络【我们的客服】以便我们根据您的申请采取相应措施。您也可以在</font><font face="微软雅黑">“账号管理-安全信息”自主进行登陆密码修改。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">六、政策的更新</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所享有的权利。我们会在专门页面上发布对本政策所做的任何变更。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">对于重大变更，我们还会提供更为显著的通知（包括我们会通过</font><font face="微软雅黑">YMS平台公示的方式进行通知或向您提供弹窗提示）。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">本政策所指的重大变更包括但不限于：</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、产品的业务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、业务调整、交易并购引起的隐私政策的主体变更，新主体接收方变更原先的处理目的、处理方式；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">3、个人信息共享或公开披露的主要对象发生变化；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">4、用户个人信息权利及其行使方式发生重大变化；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">5、处理个人信息保护负责人的联络方式及投诉渠道发生变化时；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">6、个人信息保护影响评估报告表明产品存在对个人权益有重大影响时。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">我们还会将本政策的旧版本在</font><font face="微软雅黑">YMS平台专门页面存档，供您查阅。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">七、未成年人保护</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如果没有父母或监护人的同意，儿童不得创建自己的用户帐号。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策与《儿童个人信息保护规则及监护人须知》，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">若您是儿童的父母或其他监护人，请您关注儿童是否是在取得您的授权同意之后使用我们的服务。如您对儿童的个人信息有疑问，请与我们的客服联系。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">八、联系我们</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">您可以通过以下方式与我们联系，我们将在</font><font face="微软雅黑">15天内回复您的请求：</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">1、如您对本政策内容有任何疑问、意见或建议，您可通过YMS平台客服（4</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;">00-0600-405</span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">）与我们联系；</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">2、您可以通过邮寄您的投诉或举报材料（请您注明与YMS平台有关，并说明具体事实情况），我们的邮寄地址是：【深圳市南山区学苑大道南山智园C1栋6楼】。需注意的是，我们可能不会回复与本政策或您的个人信息权利无关的问题。</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">附录：相关定义</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS平台：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">指</font><font face="微软雅黑">YMS平台（所涉域名为 </font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">yms.com</span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">）网站及客户端。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">YMS平台服务提供者：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">指</font><font face="微软雅黑">YMS平台的互联网信息及软件技术服务提供者深圳云卖供应链管理有限公司。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">个人信息：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">敏感个人信息：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹信息，以及不满十四周岁未成年人的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">儿童：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">指不满十四周岁的未成年人。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">设备信息：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">包括设备标识符（</font><font face="微软雅黑">IMEI、IDFA、Android ID、MAC、OAID、IMSI及其他设备相关信息）、应用信息（应用崩溃信息、通知开关状态、应用安装列表及其他应用相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息（IP地址，WiFi信息，基站信息及其他网络相关信息）。以产品实际采集情况为准。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">服务日志信息：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">包括浏览记录、点击查看记录、搜索查询记录、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及</font><font face="微软雅黑">IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间及您访问的网页记录。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">去标识化：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">匿名化：</font></span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="微软雅黑">指个人信息经过处理无法识别特定自然人且不能复原的过程。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"></span></p>
    <p class=MsoNormal style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
mso-margin-bottom-alt:auto;line-height:18.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;">&nbsp;</span></p>
  </div>
</template>

<script>

export default {
  name: "PrivacyPolicyCN",
  data() {
    let v = this;
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "等线 Light";
}

@font-face {
  font-family: "微软雅黑";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h1 {
  mso-style-name: "标题 1";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 1;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

h2 {
  mso-style-name: "标题 2";
  mso-style-next: 正文;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  mso-outline-level: 2;
  font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
  font-size: 18.0000pt;
}

h3 {
  mso-style-name: "标题 3";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 13.0000pt;
  margin-bottom: 13.0000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 3;
  line-height: 173%;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 16.0000pt;
  mso-font-kerning: 1.0000pt;
}

h5 {
  mso-style-name: "标题 5";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 14.0000pt;
  margin-bottom: 14.5000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 5;
  line-height: 156%;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 14.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeading8 {
  mso-style-name: "标题 8";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 12.0000pt;
  margin-bottom: 3.2000pt;
  page-break-after: avoid;
  mso-pagination: lines-together;
  text-align: justify;
  text-justify: inter-ideograph;
  mso-outline-level: 8;
  line-height: 133%;
  font-family: '等线 Light';
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.10 {
  font-family: 'Times New Roman';
}

span.15 {
  font-family: Calibri;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.16 {
  font-family: 'Times New Roman';
  mso-ansi-font-weight: bold;
}

span.17 {
  font-family: 'Times New Roman';
  mso-ansi-font-style: italic;
}

span.18 {
  font-family: 'Times New Roman';
  font-size: 10.5000pt;
}

span.19 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 16.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.20 {
  font-family: 宋体;
  mso-ansi-font-weight: bold;
  font-size: 24.0000pt;
  mso-font-kerning: 22.0000pt;
}

span.21 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.22 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 14.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.23 {
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

span.24 {
  font-family: Calibri;
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.25 {
  font-family: '等线 Light';
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentText {
  mso-style-name: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

p.p {
  mso-style-name: "普通\(网站\)";
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
}

p.MsoFooter {
  mso-style-name: 页脚;
  margin: 0pt;
  margin-bottom: .0001pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoHeader {
  mso-style-name: 页眉;
  margin: 0pt;
  margin-bottom: .0001pt;
  border-bottom: 1.0000pt solid windowtext;
  mso-border-bottom-alt: 0.7500pt solid windowtext;
  padding: 0pt 0pt 1pt 0pt;
  layout-grid-mode: char;
  mso-pagination: none;
  text-align: center;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 9.0000pt;
  mso-font-kerning: 1.0000pt;
}

p.MsoCommentSubject {
  mso-style-name: 批注主题;
  mso-style-parent: 批注文字;
  mso-style-next: 批注文字;
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-weight: bold;
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.3000pt 841.9000pt;
  layout-grid: 15.6000pt;
  mso-header-margin: 42.5500pt;
  mso-footer-margin: 49.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>