import ymsApi from '@/api/ymsApi';
import supplierApi from '@/api/supplierApi';
import distributorApi from '@/api/distributorApi';

let origin = window.location.origin;
let pathname = window.location.pathname;

let pathnameList = [
  // (在商城中公共的基础配置信息的接口需要走yms-distributor-service，业务接口默认走 yms-core-service)
  {pathname: '/index.html', api: Object.assign({}, ymsApi, distributorApi)}, // 云卖商城
  {pathname: '/storeList.html', api: Object.assign({}, ymsApi, distributorApi)}, // 云卖商城
  {pathname: '/login.html', api: Object.assign({}, ymsApi,distributorApi)}, // 云卖商城登录
  {pathname: '/yms.html', api: Object.assign({}, ymsApi)}, // 运营系统
  {pathname: '/supplier.html', api: Object.assign({}, supplierApi)}, // 供应商系统
  {pathname: '/distributor.html', api: Object.assign({}, distributorApi)}, // 分销商系统
];

let api = '';
// 校验是否是访问店铺列表的域名
if (window.shop === 'shopping_tag') {
  api = Object.assign({}, ymsApi, distributorApi);
} else {
  pathnameList.map((item) => {
    if (pathname.includes(item.pathname)) {
      api = item.api;
    }
  });
}

export default api;
