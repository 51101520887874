/**
 * @Description: 全局注册公共常用的工具类处理方法
 * @author jing
 * @date 2020/12/25 11:07
 * @params:
 */

import Vue from 'vue';
import uDate from '@/utils/tools/date';
import regular from '@/utils/tools/regular';

Vue.prototype.$uDate = uDate;
Vue.prototype.$regular = regular;

const install = function (Vue, opts = {}) {
  if (install.installed) return;
};
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
Vue.use(install);
