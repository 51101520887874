/**
 * @Description: 代理配置文件
 * @author gan
 * @date: 2020/12/16 15:09
 */

const PROXY_URL = {
  admin: 'https://admin-test.pvt.yms.com/',
  supplier: 'https://supplier-uat.pvt.yms.com/',
  dis: 'https://supplier.yms.com/',
};

let target_api = PROXY_URL.dis;
// eslint-disable-next-line no-undef
module.exports = {
  '^/yms-core-service': {
    target: 'http://localhost:8123/',
    changeOrigin: true,
    ws: false,
    onProxyRes: function (proxyRes, req, res) {
      proxyRes.headers['area'] = 'yms-core-service';
    },
    pathRewrite: function (path, req, res) {
      let service = path.split('/')[1];
      path = path.replace(/^\/yms-core-service/, '');
      if (path.indexOf('?') > -1) {
        path = path + '&' + service;
      } else {
        path = path + '?&' + service;
      }
      return path;
    }
  },
  '^/yms-supplier-service': {
    target: 'http://localhost:8123/',
    changeOrigin: true,
    ws: false,
    onProxyRes: function (proxyRes, req, res) {
      proxyRes.headers['area'] = 'yms-supplier-service';
    },
    pathRewrite: function (path, req, res) {
      let service = path.split('/')[1];
      path = path.replace(/^\/yms-supplier-service/, '');
      if (path.indexOf('?') > -1) {
        path = path + '&' + service;
      } else {
        path = path + '?&' + service;
      }
      return path;
    }
  },
  '^/yms-distributor-service': {
    target: 'http://localhost:8123/',
    changeOrigin: true,
    ws: false,
    onProxyRes: function (proxyRes, req, res) {
      proxyRes.headers['area'] = 'yms-distributor-service';
    },
    pathRewrite: function (path, req, res) {
      let service = path.split('/')[1];
      path = path.replace(/^\/yms-distributor-service/, '');
      if (path.indexOf('?') > -1) {
        path = path + '&' + service;
      } else {
        path = path + '?&' + service;
      }
      return path;
    }
  },
  '/': {
    target: target_api,
    changeOrigin: true, // 如果接口跨域，需要进行这个参数配置
    pathRewrite: function (path) {
      let service = path.split('&')[path.split('&').length - 1];
      return '/' + service + path.replace('&' + service, '').replace(/\?$/, '');
    }
  }
};
