export default {
  token_time: 1500000, //25分钟
  curTime: 300000, // 5分钟
  adidTime: 259200000, // adid写入cookie，72小时有效
  SERVICE: 'yms-service',
  ymsShopping: 'yms-shopping-service',
  ymsDistributor: 'yms-distributor-service',
  ymsSupplier: 'yms-supplier-service',
  ymsCore: 'yms-core-service',
};
