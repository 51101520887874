
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';
// 多语种动态日志-- 目前只处理分销商系统涉及的日志
import {setConfigCharacters} from "@/utils/common";

export const dynamicLog = (obj, contentType, system) => {
  // 动态类型值
  let typeObj = {
    LF: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001540'),
    FT: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001541')
  }

  // 处理地址的动态日志数据问题
  function handleAddress(obj) {
    let newList = [];
    let addressLoglist = [
      {
        paramsKey: ['oldBuyerName', 'newBuyerName'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001356')} [ ${obj.oldBuyerName} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerName} ]`
      },
      {
        paramsKey: ['oldBuyerEmail', 'newBuyerEmail'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001357')} [ ${obj.oldBuyerEmail} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerEmail} ]`
      },
      {
        paramsKey: ['oldBuyerPhone', 'newBuyerPhone'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001358')} [ ${obj.oldBuyerPhone} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerPhone} ]`
      },
      {
        paramsKey: ['oldBuyerAccountId', 'newBuyerAccountId'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001359')} [ ${obj.oldBuyerAccountId} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerAccountId} ]`
      },
      {
        paramsKey: ['oldBuyerCountryCode', 'newBuyerCountryCode'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001360')} [ ${obj.oldBuyerCountryCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerCountryCode} ]`
      },
      {
        paramsKey: ['oldBuyerMobile', 'newBuyerMobile'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001361')} [ ${obj.oldBuyerMobile} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerMobile} ]`
      },
      {
        paramsKey: ['oldBuyerAddress1', 'newBuyerAddress1'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001362')} [ ${obj.oldBuyerAddress1} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerAddress1} ]`
      },
      {
        paramsKey: ['oldBuyerAddress2', 'newBuyerAddress2'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001363')} [ ${obj.oldBuyerAddress2} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerAddress2} ]`
      },
      {
        paramsKey: ['oldBuyerCity', 'newBuyerCity'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001364')}  [ ${obj.oldBuyerCity} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerCity} ]`
      },
      {
        paramsKey: ['oldBuyerState', 'newBuyerState'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001365')} [ ${obj.oldBuyerState} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerState} ]`
      },
      {
        paramsKey: ['oldBuyerPostalCode', 'newBuyerPostalCode'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001366')} [ ${obj.oldBuyerPostalCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerPostalCode} ]`
      },
      {
        paramsKey: ['oldBuyerPassportCode', 'newBuyerPassportCode'],
        value: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001367')} [ ${obj.oldBuyerPassportCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000442')} [ ${obj.newBuyerPassportCode} ]`
      },
    ];
    if (Object.keys(obj).length > 0) {
      for (let key in obj) {
        addressLoglist.map((item) => {
          if (item.paramsKey.includes(key)) {
            newList.push(item.value)
          }
        })
      }
    }
    newList.unshift(`${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001368')} ${obj.packageCode} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001369')}`);
    let text = [...new Set(newList)].join(';');
    return text;
  }

  // 处理有些动态日志不需要展示在分销商但是需要展示运营
  function handleDynamicLogs(obj, system) {
    let text = '';
    if (contentType === 40) {
      if (system === 'distributor') {
        text = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001370')}${obj.currency} ${obj.amount}`;
      } else if (system === 'yms') {
        text = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001371')}${obj.currency} ${obj.amount}`;
      }
    }
    if (contentType === 48) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001372')}${obj.totalPriceCurrency}  ${obj.totalPrice}
         ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001373')}${obj.totalPriceCurrency}  ${obj.distributorStockCost}
         ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001374')}${obj.supplierTotalPriceCurrency}  ${obj.supplierTotalPrice}`
      }
    }
    if (contentType === 52) {
      if (system === 'distributor') {
        text = '';
      } else if (system === 'yms') {
        text = `${obj.subPackageCode} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001375')}`;
      }
    }
    return text;
  }

  /*;是用于页面显示的时候换行展示*/
  let logsObj = {
    1: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001376')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001377')}`,
    2: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001378')}`,
    3: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001379')}`,
    4: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001380')}`,
    5: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001381')}`,
    6: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001382')}`,
    7: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001383')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ]`,
    8: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001385')} ${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001386')} ${obj.ruleName} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001387')} ${obj.ymsLogisName} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001388')} ${typeObj[obj.dynamicMessage]}`,
    9: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001389')}${obj.platformItemSku}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001390')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    10: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001391')}${obj.platformItemSku}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001392')}${setConfigCharacters}SKU`,
    11: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001393')}${obj.platformItemSku}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001390')}${setConfigCharacters}SKU：${obj.ymsSku}`,
    12: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001394')}${obj.platformItemSku}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001390')}${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001395')}`,
    13: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001394')}${obj.platformItemSku}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001392')}${setConfigCharacters}SKU`,
    14: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001385')} ${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ]`,
    15: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001396')}`,
    16: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001397')}${obj.carrierShippingMethodName} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001398')}${obj.trackingNumber} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001399')}${obj.thirdPartyNo}`,
    17: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001400')}${obj.message}`,
    18: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001401')}${obj.trackingNumber}`,
    19: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001402')}${obj.message}`,
    20: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001403')}`,
    21: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001404')}`,
    22: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000916')} ${obj.packageCode} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001405')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001406')})`,
    23: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001384')} [ ${obj.packageCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001403')} ${obj.message}`,
    24: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001368')} ${obj.packageCode} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001407')}`,
    25: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001368')} ${obj.packageCode} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001408')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001409')} [ ${obj.oldCarrierContent} ] ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001410')} [ ${obj.newCarrierContent} ]`,
    26: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001368')} ${obj.packageCode} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001411')}`,
    27: handleAddress(obj),
    28: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001412')}`,
    29: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001413')}`,
    30: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000508')}`,
    31: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000507')}`,
    32: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001414')}`,
    33: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001415')}`,
    34: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001416')}${obj.iossName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001417')}${obj.iossCode}`,
    35: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001418')}${obj.iossName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001417')}${obj.iossCode}`,
    36: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001419')}${obj.iossName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001420')}${obj.iossCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001421')}${obj.platformIds}`,
    37: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001419')}${obj.iossName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001420')}${obj.iossCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001422')}${obj.shopIds}`,
    38: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001423')}${obj.iossName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001420')}${obj.iossCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001421')}${obj.platformIds}`,
    39: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001423')}${obj.iossName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001420')}${obj.iossCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001422')}${obj.shopIds}`,
    40: handleDynamicLogs(obj, system),
    41: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001424')}${obj.currency} ${obj.amount}`,
    42: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001425')}${obj.currency} ${obj.amount}`,
    43: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001426')}`,
    44: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001427')}`,
    45: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001428')}`,
    46: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001429')} ${obj.amount}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001430')}${obj.currency}`,
    47: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001431')}`,
    48: handleDynamicLogs(obj, system),
    49: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001432')}`,
    50: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001433')}${obj.estimateArrivalOfGoodsTime}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001434')}${obj.ymsSkuContent}`,
    51: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001435')}${obj.ymsSkuContent}`,
    52: handleDynamicLogs(obj, system),
    53: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001436')}`,
    54: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001437')}`,
    55: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000507')}${obj.cnValue}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000217')}`,
    56: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000508')}${obj.cnValue}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000217')}`,
    57: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001013')}${obj.cnValue}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000217')}`,
    58: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000226')}${obj.content}`,
    59: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001438')}`,
    60: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001439')}`,
    61: `${obj.subPackageCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001375')}`,
    62: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001440')}`,
    63: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001441')}`,
    64: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001442')}${obj.carrierShippingMethodName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001443')}${obj.trackingNumber}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001444')}${obj.thirdPartyNo}`,
    65: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001445')}`,
    66: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001446')}`,
    67: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001447')}`,
    68: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001448')}`,
    69: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001449')}`,
    70: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001450')}`,
    71: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001451')}${obj.rate}`,
    72: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001452')}${obj.rate}`,
    73: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001453')}`,
    74: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001454')}`,
    75: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001455')}`,
    76: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001456')}${obj.ymsPickingOrderCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001457')}`,
    77: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001225')}`,
    78: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001458')}`,
    79: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001459')}`,
    80: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001460')}${obj.code}】`,
    81: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001461')}`,
    82: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001462')}`,
    83: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001463')}`,
    84: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001464')}`,
    85: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001465')}`,
    86: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001466')}`,
    87: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001467')}`,
    88: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000665')}`,
    89: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001468')}${obj.describe}`,
    90: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001469')}  ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001470')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001471')}${obj.describe}`,
    91: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001472')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001470')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000665')}`,
    92: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001469')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001470')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000665')}`,
    93: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001472')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001470')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001473')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001468')}${obj.describe}`,
    94: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001474')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001475')}${obj.subsidyAmount}${obj.subsidyAmountCurrency}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001476')}${obj.modifySubsidyMoney}${obj.subsidyAmountCurrency}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001477')}${obj.describe}`,
    95: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001467')}`,
    96: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001478')}`,
    97: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001479')}`,
    98: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001480')}`,
    99: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001481')}`,
    100: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001482')}`,
    101: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001483')}`,
    102: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001484')}${obj.auditRemarks}`,
    103: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001485')}`,
    104: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001486')}`,
    105: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001487')}`,
    106: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001488')}${obj.carrierName}：${obj.carrierShippingMethodName}`,
    107: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001489')}`,
    108: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001223')}`,
    109: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001490')}${obj.packageCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001491')}${obj.warehouseName}`,
    110: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001493')}${obj.invalidReason || alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001492')}`,
    111: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001494')}${obj.oldName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001495')}${obj.newName}]`,
    112: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000916')}${obj.packageCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001385')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001496')}(${obj.ruleName})`,
    113: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000916')}${obj.packageCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001385')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001497')}`,
    114: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000916')}${obj.packageCode}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001385')}${obj.carrierName}_${obj.carrierShippingMethodName}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001498')}`,
    115: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001499')}`,
    116: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001500')}`,
    117: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001501')}`,
    118: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001502')}`,
    119: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001503')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001504')}${obj.trackingNumber})`,
    120: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001500')} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001505')}${obj.carrierErrorMsg})`,
    121: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001506')}`,
    122: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001507')}`,
    123: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001508')}`,
    124: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001509')}${obj.businessId}】`,
    125: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001510')}`,
    126: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001511')}`,
    127: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001512')}`,
    128: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001513')}`,
    129: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001514')}`,
    130: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001515')}`,
    131: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001516')}`,
    132: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001517')}`,
    133: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001518')}`,
    134: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001519')}${obj.invalidReason}`,
    135: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001520')}`,
    136: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001521')}`,
    137: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001522')}`,
    138: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001523')}${obj.renewalPrice}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001524')}${obj.renewalMonth} ${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001525')}`,
    139: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001526')}`,
    140: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001527')}${obj.auditRemarks}`,
    141: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001528')}${obj.content}`,
    142: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001529')}`,
    143: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001530')}${obj.packageTagName}`,
    144: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001531')}${obj.packageTagName}`,
    145: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001532')}`,
    146: `${setConfigCharacters}${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001533')}${obj.content}`,
    147: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001534')}`,
    148: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001535')}`,
    149: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001536')}`,
    150: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001537')}`,
    151: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001538')}${obj.ymsSpu}`,
    152: `${alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001539')}${obj.ymsSku}`,
  };
  return logsObj[contentType];

}
