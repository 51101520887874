import Vue from 'vue';
import VueI18n from 'vue-i18n';
import localfefbff2c10f74f118ea137bea88a29bb from './lang/en-US';
import local22bcc6770c834e969f7010cece16149c from './lang/ko-KR';
import localc923eabf28674871aaa5e23e73a5cb4d from './lang/zh-CN';

Vue.use(VueI18n);
function handleSystemLanguage() {
  let language = '';
  let defaultLanguage = window.navigator.language;
  if (defaultLanguage) {
    if (['zh', 'zh-CN'].includes(defaultLanguage)) {
      language = 'zh-CN';
    } else if (['en', 'en-US'].includes(defaultLanguage)) {
      language = 'en-US';
    } else {
      language = 'zh-CN';
    }
    return language;
  }
}
let lang = localStorage.getItem('lang')　|| handleSystemLanguage() || 'zh-CN';
localStorage.setItem('lang', lang);
Vue.config.lang = lang;

var localeMap = {"en-US":"English","ko-KR":"한국어","zh-CN":"简体中文"}
const messages = {'en-US':{...localfefbff2c10f74f118ea137bea88a29bb},'ko-KR':{...local22bcc6770c834e969f7010cece16149c},'zh-CN':{...localc923eabf28674871aaa5e23e73a5cb4d}};
export const i18n = new VueI18n({
				  locale: lang,
				  messages
				});
